import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getTo} from "../../../redux/home-reducer";


const CountryButtons = (props) => {

    const renderButtons = (items) => {
        let response = props.hom.vacancies_category.map((item, index) => {
            if(item.acf.button_in_vacancies === true){
                return <li key={index}>
                    <Link className="button_flag" to={props.getTo(item.link)}>
                        <img alt="flag" src={item.acf.flag.url}/>
                        {item.acf.button_title}
                    </Link>
                </li>
            }
        });

        return <ul className="buttons_flag">{response}</ul>
    }
    return renderButtons()
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
    }
}

export default connect(mapStateToProps,
    {getTo})(CountryButtons);

