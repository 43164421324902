import {mainApi} from "../api/main-api";

const SET_VISA_APPLICATION = 'SET_VISA_APPLICATION';

let initialState = {
    visas: [],
    visas_keys: {ru: 16, ua: 1223, de: 1195},
    loading: true,
};

const VisaApplicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VISA_APPLICATION: {
            return {
                ...state,
                visas: action.data.data,
            }
        }

        default:
            return state;
    }

}

export const setVisaApplication = (data) => ({type: SET_VISA_APPLICATION, data})


export const getVisaApplication = () => {
    return (dispatch, getState) => {
        let language = getState().homeReducer.language
        let visas_keys = getState().VisaApplicationReducer.visas_keys
        let data = {parent: visas_keys[language]}
        // debugger
        mainApi.getPage(data).then(data => {
            dispatch(setVisaApplication(data))
        });
    }
}

export default VisaApplicationReducer;