import {mainApi} from "../api/main-api";

const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
const SET_WINDOW_SCROLL = 'SET_WINDOW_SCROLL';
const SET_POPUP_CALLBACK = 'SET_POPUP_CALLBACK';
const SET_POPUP_QUESTION = 'SET_POPUP_QUESTION';
const SET_POPUP_CLOSE = 'SET_POPUP_CLOSE';
const SET_PHONES_DROPDOWN = 'SET_PHONES_DROPDOWN';
const SET_MENU_MOBILE = 'SET_MENU_MOBILE';
const SET_FILTERS_MOBILE = 'SET_FILTERS_MOBILE';

let initialState = {
    window_width: '',
    device: '',
    popup_callback: false,
    popup_question: false,
    phones_dropdown: false,
    menu_mobile: false,
    filters_mobile: false,
};

const effectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WINDOW_WIDTH: {
            let window_width = window.innerWidth
            let device
            if(window_width >= 1200){
                device = 'desktop'
            }else if(window_width <= 1199 && window_width > 767){
                device = 'tablet'
            }else{
                device = 'mobile'
            }
            return {
                ...state,
                window_width: window_width,
                device: device,
            }
        }
        case SET_WINDOW_SCROLL: {
            return {
                ...state,
                phones_dropdown: false,
            }
        }
        case SET_POPUP_CALLBACK: {
            return {
                ...state,
                popup_callback: !state.popup_callback,
            }
        }
        case SET_POPUP_QUESTION: {
            return {
                ...state,
                popup_question: !state.popup_question,
            }
        }
        case SET_POPUP_CLOSE: {
            return {
                ...state,
                popup_callback: false,
                popup_question: false,
                menu_mobile: false,
            }
        }
        case SET_PHONES_DROPDOWN: {
            return {
                ...state,
                phones_dropdown: !state.phones_dropdown,
            }
        }
        case SET_MENU_MOBILE: {
            return {
                ...state,
                menu_mobile: !state.menu_mobile,
            }
        }
        case SET_FILTERS_MOBILE: {
            return {
                ...state,
                filters_mobile: !state.filters_mobile,
            }
        }
        default:
            return state;
    }

}

export const setWindowWidth = (data) => ({type: SET_WINDOW_WIDTH, data})
export const setWindowScroll = (data) => ({type: SET_WINDOW_SCROLL, data})
export const setPopupCallback = (data) => ({type: SET_POPUP_CALLBACK, data})
export const setPopupQuestion = (data) => ({type: SET_POPUP_QUESTION, data})
export const setPopupClose = (data) => ({type: SET_POPUP_CLOSE, data})
export const setPhonesDropdown = (data) => ({type: SET_PHONES_DROPDOWN, data})
export const setMenuMobile = (data) => ({type: SET_MENU_MOBILE, data})
export const setFiltersMobile = (data) => ({type: SET_FILTERS_MOBILE, data})


export const popupCallback = (data) => {
    return (dispatch, getState) => {
        dispatch(setPopupCallback(data))
    }
}

export const popupQuestion = (data) => {
    return (dispatch, getState) => {
        // dispatch(setPopupQuestion(data))
    }
}

export const popupClose = (data) => {
    return (dispatch, getState) => {
        dispatch(setPopupClose(data))
    }
}

export const phonesDropdown = (data) => {
    return (dispatch, getState) => {
        dispatch(setPhonesDropdown(data))
    }
}

export const filtersMobile = (data) => {
    return (dispatch, getState) => {
        dispatch(setFiltersMobile(data))
    }
}


export default effectReducer;