import React, {Component} from 'react'
import {connect} from "react-redux";
import FooterTop from "./FooterTop";
import FooterBottom from "./FooterBottom";


class FooterContainer extends Component {

    constructor(props, state) {
        super(props, state);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

    }

    render() {
        return <footer id="footer">
            <FooterTop/>
            <FooterBottom/>
        </footer>
    }
}

let mapStateToProps = (state) => {
    return {
        // cart: state.cartReducer,
    }
}

export default connect(mapStateToProps,
    {})(FooterContainer);



