import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";


const Phones = (props) => {

    return <ul className="phones">
        <li><a href="tel:+380677723277">+38<span> (067) </span>772-32-77</a></li>
        <li><a href="tel:+380979076077">+38<span> (097) </span>907-60-77</a></li>
        <li><a href="tel:+48574065206">+4<span> (857) </span>406-52-06</a></li>
        <li><a href="tel:+380677742377">+38<span>(067)</span> 774-23-77</a></li>
    </ul>
}

let mapStateToProps = (state) => {
    return {
        // lang: state.languageReducer,
    }
}

export default connect(mapStateToProps,
    {})(Phones);

