import React, {Component} from 'react'
import {connect} from "react-redux";

const JobInEurope = (props) => {

    const items = (items) => {
        let response = []
        if (items) {
            response = items.map((item, index) => {
                return <li key={index} dangerouslySetInnerHTML={{__html: item.name}}/>
            });
        }

        return <ul className="list_style_type_double_check">{response}</ul>
    }

    let job_in_europe = props.hom.home.acf ? props.hom.home.acf.job_in_europe : ''

    return <div className="job_in_europe box">
        <div className="container">
            <h2 className="title_head">{job_in_europe.title}</h2>
            {items(job_in_europe.items)}
        </div>
    </div>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
    }
}

export default connect(mapStateToProps,
    {})(JobInEurope);
