import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Img from "../Elements/Img/Img";
import {getExcerpt, getThumbnail, getTitle} from "../../redux/page-reducer";
import {getTo} from "../../redux/home-reducer";


const BlogItems = (props) => {

    const renderItems = (items = []) => {

        let response = items.map((item, index) => {
            let thumbnail = props.getThumbnail(item, 'blog')
            let title = props.getTitle(item)
            let excerpt = props.getExcerpt(item)
            return <li key={index}>
                <Link to={props.getTo(item.link)}>
                    <Img
                        src={thumbnail.src}
                        alt={thumbnail.alt}
                    />
                    <div className="bottom">
                        <p className="title" dangerouslySetInnerHTML={{__html: title}}/>
                        <div className="description" dangerouslySetInnerHTML={{__html: excerpt}}/>
                    </div>
                </Link>
            </li>
        });
        return <ul className="items">{response}</ul>
    }
    return renderItems(props.blogItems)
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
    }
}

export default connect(mapStateToProps,
    {getTitle, getThumbnail, getExcerpt, getTo})(BlogItems);

