import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Select} from "antd";
import {selectCity, start} from "../../../redux/home-reducer";

const {Option} = Select;

const Language = (props) => {

    let cities = props.hom.languages.map((item, index) => {
        return <Option key={index} value={item.slug}>
            <Link to={item.home_url ? item.home_url.replace(process.env.REACT_APP_DOMAIN, '') : '/'}>
                <img src={item.custom_flag_url} alt={item.name}/>
            </Link>
        </Option>
    })

    return <div className="language">
        <Select
            onChange={(value) => {
                props.start()
            }}
            defaultValue={props.hom.language}
            dropdownClassName="language_dropdown"
            // value={props.hom.city}
        >
            {cities}
        </Select>

    </div>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
    }
}

export default connect(mapStateToProps,
    {selectCity, start})(Language);

