import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Img from "../../Elements/Img/Img";
import PriceBlock from "../../Vacancy/PriceBlock/PriceBlock";
import Icon from "../../Elements/icon/Icon";
import {getCountry, getFlag, getThumbnail, getTitle} from "../../../redux/page-reducer";
import {getLanguageStrings, getTo} from "../../../redux/home-reducer";
import {changeVacanciesPage, getVacancy} from "../../../redux/vacancies-reducer";


const HotVacancies = (props) => {

    const renderComponent = () => {

        let response = props.hot_vacancies.map((item, index) => {
            let title = props.getTitle(item)
            let thumbnail = props.getThumbnail(item, 'vacancy')
            let country = props.getCountry(item)
            let flag = props.getFlag(country)

            return <li key={index}>

                <Img
                    src={thumbnail.src}
                    alt={thumbnail.alt}
                    class="thumbnail"
                />
                <div className="panel">
                    <p className="title" dangerouslySetInnerHTML={{__html: title}}/>
                    <PriceBlock
                        vacancy={item}
                    />
                    <Link key={index} to={props.getTo(item.link)}>
                        <p className="button red">{props.language.podrobnee}</p>
                    </Link>
                </div>

            </li>
        });



        return <div className="hot_vacancies box dusk">
            <div className="container">
                <h2 className="title_head">{props.language.goryachie_vakansii}</h2>
                <ul className="items">{response}</ul>
                <Link to="/vacancies" className="button blue button_big">
                    {props.language.smotret_vse_vakansii}
                    <img src="/img/arrow-3.svg" />
                </Link>
            </div>
        </div>

    }


    return renderComponent()
}

let mapStateToProps = (state) => {
    return {
        hot_vacancies: state.vacanciesReducer.hot_vacancies,
        language: state.homeReducer.home.acf ? state.homeReducer.home.acf.language : '',
    }
}

export default connect(mapStateToProps,
    {getTitle, getThumbnail, getTo, getCountry, getFlag, changeVacanciesPage, getVacancy, getLanguageStrings})(HotVacancies);

