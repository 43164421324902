import React, {Component} from 'react'
import {connect} from "react-redux";
import Logo from "./Logo";
import City from "./City";
import Language from "./Language";
import CallbackButtons from "./CallbackButtons";
import Phones from "../../Elements/Phones/Phones";
import Menu from "./Menu";
import {phonesDropdown, setMenuMobile} from "../../../redux/effect-reducer";
import PhoneMenuButtons from "./PhoneMenuButtons";


class HeaderContainer extends Component {

    constructor(props, state) {
        super(props, state);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

    }

    render() {

        const renderHeader = () => {
            switch (this.props.effect.device) {
                case 'desktop':
                    return <div className="header ">
                        <div className="top container">
                            <Logo/>
                            <City/>
                            <Language/>
                            <CallbackButtons/>
                            <Phones/>
                        </div>
                        <div className="bottom">
                            <div className="container">
                                <Menu
                                    menu={this.props.home.menu}
                                />
                            </div>
                        </div>
                    </div>
                case 'tablet':
                    return <div className="header">
                        <div className="top container">
                            <Logo/>
                            <City/>
                            <Language/>
                            <PhoneMenuButtons/>
                        </div>
                        <div className={this.props.effect.phones_dropdown ? 'phones_dropdown active' : 'phones_dropdown'}>
                            <div className="container">
                                <Phones/>
                                <CallbackButtons/>
                            </div>
                        </div>
                        <div className={this.props.effect.menu_mobile ? 'menu_dropdown active' : 'menu_dropdown'}>
                            <Menu
                                menu={this.props.home.menu}
                            />
                        </div>
                    </div>
                case 'mobile':
                    return <div className="header">
                        <div className="top container">
                            <Logo/>
                            <PhoneMenuButtons/>
                        </div>
                        <div className={this.props.effect.phones_dropdown ? 'phones_dropdown active' : 'phones_dropdown'}>
                            <div className="container">
                                <Phones/>
                                <CallbackButtons/>
                            </div>
                        </div>
                        <div className={this.props.effect.menu_mobile ? 'menu_dropdown active' : 'menu_dropdown'}>
                            <Menu
                                menu={this.props.home.menu}
                            />
                            <City/>
                            <Language/>
                        </div>
                    </div>
            }
        }

        return renderHeader()


    }
}

let mapStateToProps = (state) => {
    return {
        home: state.homeReducer,
        effect: state.effectReducer,
    }
}

export default connect(mapStateToProps,
    {phonesDropdown, setMenuMobile})(HeaderContainer);



