import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Phones from "../../Elements/Phones/Phones";
import Address from "../../Elements/Address/Address";
import Mail from "../../Elements/Mail/Mail";
import {popupCallback} from "../../../redux/effect-reducer";


const FooterTop = (props) => {


    return <div className="top">
        <div className="container">
            <div className="left">
                <p className="footer_title">{props.language.kontaktnaya_informaciya}</p>
                <Address/>
                <Phones/>
                <Mail/>
                <div className="buttons">
                    <p onClick={props.popupCallback} className="button red">{props.language.zakazat_zvonok}</p>
                    <p onClick={props.popupCallback} className="button red">{props.language.zadat_vopros}</p>
                </div>
            </div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4364.339590586178!2d26.979659258189937!3d49.42683381313957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473207b33a2a7307%3A0x7b5e3217219e1bcf!2z0JLQuNC30L7QstGL0Lkg0YbQtdC90YLRgCDCq9Cy0LjQt9CwINGB0LXRgNCy0LjRgSA5MTHCuw!5e0!3m2!1sru!2sua!4v1647245727245!5m2!1sru!2sua"
                width="570" height="526" style={{border: 0}} allowFullScreen="" loading="lazy"/>
        </div>
    </div>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
        language: state.homeReducer.home.acf ? state.homeReducer.home.acf.language : '',
    }
}

export default connect(mapStateToProps,
    {popupCallback})(FooterTop);

