import React from 'react'
import {connect} from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Img from "../../Elements/Img/Img";
import SlickArrowLeft from "../../Elements/SlickArrow/SlickArrowLeft";
import SlickArrowRight from "../../Elements/SlickArrow/SlickArrowRight";

const PartnerLicenseCertificates = (props) => {


    const renderPartners = (items = []) => {


        const settings = {
            prevArrow: <SlickArrowLeft />,
            nextArrow: <SlickArrowRight />,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            currentSlide: 1,
            responsive: [
                {breakpoint: 1199, settings: {slidesToShow: 3}},
                {breakpoint: 991, settings: {slidesToShow: 2}},
                {breakpoint: 575, settings: {slidesToShow: 1}},
            ]
        };
        if(items){
            let response = items.map((item, index) => {
                return <div key={index} className="item">
                    <Img
                        src={item.img ? item.img.sizes.medium : ''}
                        alt={item.img ? item.img.alt : ''}
                        class="thumbnail"
                    />
                </div>
            });
            return <Slider {...settings}>{response}</Slider>
        }else {
            return ''
        }

    }

    // const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    //     <img {...props} src="/img/arrow-left-big.svg"/>
    // )

    const renderCertificates = (items = []) => {
        const settings = {
            prevArrow: <SlickArrowLeft />,
            nextArrow: <SlickArrowRight />,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {breakpoint: 1199, settings: {slidesToShow: 3}},
                {breakpoint: 991, settings: {slidesToShow: 2}},
                {breakpoint: 575, settings: {slidesToShow: 1}},
            ]
        };
        if(items){
            let response = items.map((item, index) => {
                return <div key={index} className="item">
                    <Img
                        src={item.img ? item.img.sizes.vacancy : ''}
                        alt={item.img ? item.img.alt : ''}
                        class="thumbnail"
                    />
                </div>
            });
            return <Slider {...settings}>{response}</Slider>
        }else {
            return ''
        }

    }


    return <div className="box dusk partners">
        <div className="container">
            <h3 className="title_head">{props.partners.title_1}</h3>
            {renderPartners(props.partners.list_1)}
            <span className="separate"/>
            <h2 className="title_head">{props.partners.title_2}</h2>
            {renderCertificates(props.partners.list_2)}
        </div>
    </div>

}

let mapStateToProps = (state) => {
    return {
        partners: state.homeReducer.home.acf ? state.homeReducer.home.acf.partners : '',
    }
}

export default connect(mapStateToProps,
    {})(PartnerLicenseCertificates)
