import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getAcf, getTitle} from "../../../redux/page-reducer";
import {getTo} from "../../../redux/home-reducer";


const Visas = (props) => {

    const renderItems = (items = []) => {
        let response = items.map((item, index) => {
            let title = props.getTitle(item)
            let acf = props.getAcf(item)

            return <li>
                <p className="title"><img src={acf.visa.flag.url} alt={acf.visa.flag.alt}/>{title}</p>
                <p className="description" dangerouslySetInnerHTML={{__html: acf.visa.description}}/>
                <Link to={props.getTo(item.link) }><p className="button red">{props.language.uznat_cenu}</p></Link>
            </li>
        });
        return <ul className="visas container">{response}</ul>
    }
    return renderItems(props.visas)

}

let mapStateToProps = (state) => {
    return {
        // five_steps: state.homeReducer.home.acf ? state.homeReducer.home.acf.five_steps : '',
        visas: state.VisaApplicationReducer.visas,
        language: state.homeReducer.home.acf ? state.homeReducer.home.acf.language : '',
    }
}

export default connect(mapStateToProps,
    {getTitle, getAcf, getTo})(Visas)
