import React from 'react'
import {Button, Form, Input} from "antd";
import {connect} from "react-redux";
import MaskedInput from "antd-mask-input";


function FormItem(props) {

    const {getFieldDecorator} = props.getFieldDecorator

    let input = ''
    if (props.type === 'phone') {
        input = <MaskedInput
            onBlur={(e) => {
                // e.target.value = ''
                let length_phone = e.target.value.replace(/[^\d]/g, '').length
                if (length_phone < 10) {
                    props.getFieldDecorator.setFields({
                        phone: {
                            value: '',
                        },
                    });
                }
            }}
            name={props.name}
            mask="(111) 111-11-11"
        />
    } else if (props.type === 'select') {
        let options = props.options.map((item, index) => {
            return <option key={index} value={item.value}>{item.name}</option>
        });
        // debugger
        input = <select name={props.name}>
            {options}
        </select>

        let initialValue = props.options[0] ? props.options[0].value : ''

        return <Form.Item
            label={props.label}
        >
            {getFieldDecorator(props.name, {
                rules: [{required: props.required, message: props.message}],
                initialValue: initialValue,
            })(
                input
            )}
        </Form.Item>


    } else if (props.type === 'textarea') {
        input = <Input.TextArea
            name={props.name}
            placeholder={props.placeholder}
        />
    } else if (props.type === 'first_uppercase') {
        input = <Input
            onChange={(e) => {
                if (e.target.value) {
                    e.target.value = e.target.value[0].toUpperCase() + e.target.value.slice(1)
                }
            }}
            name={props.name}
            placeholder={props.placeholder}
        />
    } else if (props.type === 'submit'){
        input = <Button
                className={props.class}
                type="primary" htmlType="submit">
                <span>{props.placeholder}</span>
            </Button>
    } else {
        input = <Input
            name={props.name}
            placeholder={props.placeholder}
        />
    }


    return <Form.Item
        label={props.label}
    >
        {getFieldDecorator(props.name, {
            rules: [{required: props.required, message: props.message}],
        })(
            input
        )}
    </Form.Item>
}

let mapStateToProps = (state) => {
    return {
        checkout: state.checkoutReducer,
    }
}

export default connect(mapStateToProps,
    {})(FormItem);

