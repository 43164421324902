import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Breadcrumbs from "../../Layout/Breadcrumbs/Breadcrumbs";
import Img from "../../Elements/Img/Img";
import MessengersButtons from "../../Elements/Messengers/MessengersButtons";
import BlogPreview from "../../Blog/BlogPreview";
import Comments from "../../Layout/Comments/Comments";
import {getVisaApplication} from "../../../redux/visaapplication-reducer";
import Visas from "./Visas";
import HotVacancies from "../../Home/HotVacancies/HotVacancies";
import FreeConsultation from "../../Layout/Forms/FreeConsultation";
import CallingYouReceive from "../../Layout/Forms/CallingYouReceive";
import ComprehensiveAssistance from "./ComprehensiveAssistance";
import Helmet from "react-helmet";


class VisaApplication extends Component {

    constructor(props, state) {
        super(props, state)
    }

    componentDidMount() {
        document.querySelector("body").scrollTo(0,0)
    }

    componentDidUpdate() {
        //document.querySelector("body").scrollTo(0,0)
        // if(this.props.match.params.slug !== this.props.page.slug){
        //     this.props.getPost({slug: this.props.match.params.slug})
        // }
    }



    render() {

        this.props.getVisaApplication()
        let post = this.props.page.page

        return <div>
            <Helmet
                title={post.yoast_head_json ? post.yoast_head_json.og_title : ''}
                defaultTitle={post.name}
                meta={[
                    {"name": "description", "content": post.yoast_head_json ? post.yoast_head_json.og_description : ''},
                ]}
                link={post.hreflang}
            />
            <h1 dangerouslySetInnerHTML={{__html: this.props.pageReducer.title}}/>
            <Visas/>
            <FreeConsultation/>
            <CallingYouReceive/>
            <ComprehensiveAssistance/>
        </div>
    }
}

let mapStateToProps = (state) => {
    return {
        pageReducer: state.pageReducer,
        title: state.pageReducer.title,
        page: state.pageReducer,
    }
}

export default connect(mapStateToProps,
    {getVisaApplication})(VisaApplication);

