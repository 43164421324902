import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Checkbox, Form, Input, InputNumber, Slider} from "antd";
import FormItem from "../../Elements/FormItem/FormItem";
import {getVacancy, toggleFilter} from "../../../redux/vacancies-reducer";
import {filtersMobile} from "../../../redux/effect-reducer";



const Filters = (props) => {
    const renderFilters = (parent_id = 0) => {
        let result = props.hom.vacancies_category.filter((e) => {
            return e.parent === parent_id
        }).map((item, index) => {
            return <li className={'item_id_' + item.id} key={index}>
                <input
                    // checked={props.filters.includes(item.id)}
                    onChange={handleToggle}
                    value={item.id}
                    id={item.id}
                    name="vacancies_category"
                    type="checkbox"
                />
                <label htmlFor={item.id}><p>{item.name}</p></label>

                {/*<Form.Item*/}
                {/*    label={item.name}*/}
                {/*    name={parent_id}*/}
                {/*    // value={item.id}*/}
                {/*    valuePropName="checked"*/}
                {/*>*/}
                {/*    <Checkbox/>*/}
                {/*</Form.Item>*/}

                {renderFilters(item.id)}

            </li>
        })

        return result.length > 0 ? <ul>{result}</ul> : ''
    };

    const handleToggle = (event) => {
        // debugger
        // let name = event.target.name
        // let value = event.target.value
        props.toggleFilter(event.target.value)
        props.getVacancy()
    }

    const [form] = Form.useForm();
    const onFinish = (changedValues, allValues) => {
        debugger
        // props.submitFormCallback(values)
        // form.setFieldsValue({name: '', tel: '', comment: ''});
    };

    return <>
        <img className="filter_open" onClick={props.filtersMobile} src="/img/filter-btn.svg" alt="filter-open"/>
        {/*<form className={props.effect.filters_mobile ? 'filters checkbox active' : 'filters checkbox'}*/}
        {/*      onChange={handleSubmit}>*/}
        <Form
            className={props.effect.filters_mobile ? 'filters checkbox active' : 'filters checkbox'}
            onFinish={onFinish}
            onValuesChange={onFinish}
        >
            {props.effect.device !== 'desktop' ?
                <div className="top">
                    <p>Фильтр</p>
                    {props.effect.device === 'tablet' ?
                        <img onClick={props.filtersMobile} className="filter_close" src="/img/close.svg"
                             alt="close-filter"/>
                        :
                        <img onClick={props.filtersMobile} className="filter_close" src="/img/close-2.svg"
                             alt="close-filter"/>
                    }
                </div>
                : ''}
            <div className="bottom">
                {renderFilters()}
                {/*<ul>*/}
                {/*    <li>*/}
                {/*        <p>По заработной плате:</p>*/}
                {/*        <ul className="filter_slider">*/}
                {/*            <li className="filter_slider_inputs">*/}
                {/*                от*/}
                {/*                <InputNumber*/}
                {/*                    min={15000}*/}
                {/*                    max={100000}*/}
                {/*                    value={'15000'}*/}
                {/*                    // controls={false}*/}
                {/*                    // onChange={this.onChange}*/}
                {/*                />*/}
                {/*                до*/}
                {/*                <InputNumber*/}
                {/*                    min={15000}*/}
                {/*                    max={100000}*/}
                {/*                    value={'100000'}*/}
                {/*                    // onChange={this.onChange}*/}
                {/*                />*/}
                {/*                <span>грн</span>*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <Slider range*/}
                {/*                        min={15000}*/}
                {/*                        max={'100000'}*/}
                {/*                        defaultValue={[15000, 100000]}/>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <ul>*/}
                {/*            <li>*/}
                {/*                <input value={1} id="visa" name="visa" type="checkbox"/>*/}
                {/*                <label htmlFor="visa">Работа по рабочей визе</label>*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <input value={1} id="passport" name="passport" type="checkbox"/>*/}
                {/*                <label htmlFor="passport">Биометрический паспорт</label>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </li>*/}
                {/*</ul>*/}
            </div>
        </Form>
        {/*</form>*/}
    </>

}

// const WrappedRegistrationForm = Form.create()(Filters);

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
        effect: state.effectReducer,
        filters: state.vacanciesReducer.filters,
    }
}

export default connect(mapStateToProps,
    {toggleFilter, filtersMobile, getVacancy})(Filters);

