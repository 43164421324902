import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import homeReducer, {start} from "./redux/home-reducer";
import {setWindowScroll, setWindowWidth} from "./redux/effect-reducer";

import HeaderContainer from './components/Layout/Header/HeaderContainer';
import FooterContainer from "./components/Layout/Footer/FooterContainer";
import VacancyContainer from "./components/Vacancy/VacancyContainer";
import VacanciesContainer from "./components/Vacancies/VacanciesContainer";
import PopupContainer from "./components/Popups/PopupContainer";
import HomeContainer from "./components/Home/HomeContainer";
import BlogCategory from "./components/Blog/BlogCategory";
import Post from "./components/Blog/Post";
import PagesContainer from "./components/Pages/PagesContainer";


class App extends React.Component {


    constructor(props, state) {
        super(props, state);
        this.props.start()

        window.addEventListener("resize", this.props.setWindowWidth)
        this.props.setWindowWidth()
        window.addEventListener("scroll", this.props.setWindowScroll)
    }

    componentDidUpdate() {
        // if (this.props.hom.language &&
        //     this.props.hom.homes.length > 0 &&
        //     this.props.hom.home.lang !== this.props.hom.language
        // ) {
        //     // debugger
        //     // this.props.getHome()
        // }
    }

    render() {

        // язык по умолчанию- /
        let lang = this.props.hom.languages_init.filter((n) => {
            return n !== this.props.hom.language_root
        }).join('|')

        return (
            <BrowserRouter>
                <div>
                    <HeaderContainer/>
                    <Switch>
                        <Route exact path={`/(${lang})?/`} component={HomeContainer}/>
                        <Route exact path={`/(${lang})?/vacancies/`} component={VacanciesContainer}/>
                        <Route exact path={`/(${lang})?/vacancies/:slug`} component={VacanciesContainer}/>
                        {/*<Route path={"/:lang?/vacancy/"} component={VacancyContainer}/>*/}
                        <Route exact path={`/(${lang})?/vacancy/:slug`} component={VacancyContainer}/>
                        <Route exact path={`/(${lang})?/blog/`} component={BlogCategory}/>
                        <Route exact path={`/(${lang})?/blog/:slug`} component={Post}/>
                        <Route exact path={`/(${lang})?/cooperation/`} component={VacancyContainer}/>
                        <Route exact path={`/(${lang})?/*/:slug`} component={PagesContainer}/>
                        <Route exact path={`/(${lang})?/:slug`} component={PagesContainer}/>
                    </Switch>
                    <FooterContainer/>
                    <PopupContainer/>
                </div>
            </BrowserRouter>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
    }
}

export default connect(mapStateToProps,
    {start, setWindowWidth, setWindowScroll})(App);

