import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import effectReducer, {popupCallback} from "../../redux/effect-reducer";
import Phones from "../Elements/Phones/Phones";
import Messengers from "../Elements/Messengers/Messengers";
import homeReducer, {submitFormCallback} from "../../redux/home-reducer";
import {Button, Form, Input} from "antd";
import FormItem from "../Elements/FormItem/FormItem";
const { TextArea } = Input;

const PopupCallback = (props) => {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        props.submitFormCallback(values)
        form.setFieldsValue({name: '', tel: '', comment: ''});
    };

    return <div className={props.effect.popup_callback ? 'active popup popup_callback' : 'popup popup_callback'}>
        <div onClick={props.popupCallback} className="close"/>
        <div className="left">
            <p className="title" dangerouslySetInnerHTML={{__html: props.form.pozvonite_nam}}/>
            <Phones/>
            <Messengers/>
        </div>
        <div className="right">
            <p className="title" dangerouslySetInnerHTML={{__html: props.form.ili_zapolnite_formu}}/>
            <Form
                onFinish={onFinish}
                form={form}
            >
                <Form.Item
                    name="name"
                    rules={[{required: true, message: props.form.vvedite_vashe_imya}]}
                >
                    <Input
                        placeholder={props.form.vashe_imya}
                    />
                </Form.Item>
                <Form.Item
                    name="tel"
                    rules={[{required: true, message: props.form.vvedite_vash_nomer_telefona}]}
                >
                    <Input
                        placeholder={props.form.vash_nomer_telefona}
                    />
                </Form.Item>
                <Form.Item
                    name="comment"
                >
                    <TextArea
                        rows={4}
                        placeholder={props.form.kommentarij}
                    />
                </Form.Item>
                <Form.Item>
                    <Button className="button_big" type="primary" htmlType="submit">
                        {props.form.poluchit_konsultaciju}
                    </Button>
                </Form.Item>
                {props.done_form ?
                    <p className="done">Ваше сообщение отправлено</p> : ''
                }
            </Form>

        </div>
    </div>
}

// const WrappedRegistrationForm = Form.create()(PopupCallback);

let mapStateToProps = (state) => {
    return {
        effect: state.effectReducer,
        form: state.homeReducer.home.acf ? state.homeReducer.home.acf.form : '',
        load_button: state.homeReducer.load_button,
        done_form: state.homeReducer.done_form,
    }
}

export default connect(mapStateToProps,
    {popupCallback, submitFormCallback})(PopupCallback);

