import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import BlogItems from "./BlogItems";
import PaginationContainer from "../Elements/Pagination/PaginationContainer";
import {changeBlogPage, getBlog} from "../../redux/blog-reducer";
import queryString from "query-string";
import {setPopupClose} from "../../redux/effect-reducer";
import Helmet from "react-helmet";

class BlogCategory extends Component {
    constructor(props, state) {
        super(props, state)
    }

    componentDidMount() {
        this.props.setPopupClose()
        document.querySelector("body").scrollTo(0,0)
    }

    componentDidUpdate() {
        this.props.setPopupClose()
        document.querySelector("body").scrollTo(0,0)
    }

    changePagination = (page) => {
        this.props.changeBlogPage(page)
        this.props.getBlog()
        document.querySelector("body").scrollTo(0,0)
    }

    render() {
        return <div className=" dusk box blog">
            <Helmet
                title={this.props.language.blognovosti + ' - ZaKordon Work'}
                defaultTitle={this.props.language.blognovosti}
                // meta={[
                //     {"name": "description", "content": post.yoast_head_json ? post.yoast_head_json.og_description : ''},
                // ]}
                // link={post.hreflang}
            />
            <div className="container">
                <h3 className="title_head">{this.props.language.blognovosti}</h3>
                <BlogItems
                    blogItems={this.props.blog.blog}
                />
                <PaginationContainer
                    page={this.props.blog.page}
                    defaultCurrent={this.props.blog.page}
                    total={this.props.blog.total_blog}
                    onChange={this.changePagination}
                />
            </div>
        </div>

    }
}

let mapStateToProps = (state) => {
    return {
        language: state.homeReducer.home.acf ? state.homeReducer.home.acf.language : '',
        blog: state.BlogReducer,
    }
}

export default connect(mapStateToProps,
    {getBlog, changeBlogPage, setPopupClose})(BlogCategory)
