import {mainApi} from "../api/main-api";

const SET_BLOG = 'SET_BLOG';
const SET_BLOG_PAGE = 'SET_BLOG_PAGE';

let initialState = {
    blog: [],
    total_blog: '',
    page: 1,
    loading: true,
};

const BlogReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BLOG: {
            return {
                ...state,
                // blog: action.data,
                blog: action.data.data,
                total_blog: parseInt(action.data.headers['x-wp-total']),
            }
        }
        case SET_BLOG_PAGE: {
            return {
                ...state,
                page: action.data ? action.data : 1,
            }
        }
        default:
            return state;
    }

}

export const setBlog = (data) => ({type: SET_BLOG, data})
export const setBlogPage = (data) => ({type: SET_BLOG_PAGE, data})

export const changeBlogPage = (data) => {
    return (dispatch, getState) => {
        dispatch(setBlogPage(data))
    }
}

export const getBlog = (slug) => {
    return (dispatch, getState) => {
        const language = getState().homeReducer.language
        const page = getState().BlogReducer.page
        mainApi.getBlog({slug, language, page}).then(data => {
            dispatch(setBlog(data))
        });
    }
}

export default BlogReducer;