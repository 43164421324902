import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getLanguageStrings} from "../../../redux/home-reducer";
import {getCountry, getMeasurement, getPlaceOfWork, getPrice} from "../../../redux/page-reducer";


const PriceBlock = (props) => {

    let language = props.getLanguageStrings()
    // let vacancy = props.page.page

    let price = props.getPrice(props.vacancy)
    let measurement = props.getMeasurement(props.vacancy)
    let country = props.getCountry(props.vacancy)
    let place_of_work = props.getPlaceOfWork(country, props.vacancy)


    return <div className="price_block">
        {place_of_work ? <p className="place_of_work">
            <img src="/img/loca-2.svg"/>
            {place_of_work}
        </p> : ''}
        {price ? <p className="price">
            <strong>{language.zarplata}:</strong> <span>{price}</span> {measurement}
        </p> : ''}
    </div>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
    }
}

export default connect(mapStateToProps,
    {getLanguageStrings, getPrice, getMeasurement, getCountry, getPlaceOfWork})(PriceBlock);

