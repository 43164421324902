import React, {Component} from 'react'
import {connect} from "react-redux";

const Address = (props) => {

    return <>
        <div className="address">
            <img src="/img/loca-3.svg"/>
            <div>
                <img src="/img/flag-ua.svg"/>
                <p>{props.language.ukraina_hmelnickij}</p>
            </div>
        </div>
        <div className="address">
            <img src="/img/loca-3.svg"/>
            <div>
                <img src="/img/flag-poland.svg"/>
                <p>{props.language.polsha_varshava}</p>
            </div>
        </div>
    </>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
        language: state.homeReducer.home.acf ? state.homeReducer.home.acf.language : '',
    }
}

export default connect(mapStateToProps,
    {})(Address);
