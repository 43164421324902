import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Form, Button, Input} from "antd";
import {format} from "date-fns";
import {getLanguageStrings} from "../../../redux/home-reducer";
const {TextArea} = Input;



const Comments = (props) => {

    let language = props.getLanguageStrings()

    let language_comment = ''
    let num_comments = props.hom.comments.length
    if (num_comments === 0) {
        language_comment = ''
    } else if (num_comments === 1) {
        language_comment = num_comments + " " + language.kommentarij
    } else if (num_comments >= 2 && num_comments <= 4) {
        language_comment = num_comments + " " + language.kommentariya
    } else {
        language_comment = num_comments + " " + language.kommentariev
    }

    const renderComments = (parent_id = 0) => {
        let parents = props.hom.comments.filter((e) => {
            return e.parent === parent_id
        }).map((item, index) => {
            let children = renderComments(item.id)
            let date = new Date(item.date)
            let formattedDate = format(date, "dd-MM-yyyy HH:mm")
            return <li className={children ? 'has_child' : ''} key={index}>
                <img src="/img/avatar.svg"/>
                <div>
                    <div className="comment_description">
                        <p className="name">{item.author_name}</p>
                        <img src="/img/clock.svg"/>
                        <p className="date">{formattedDate}</p>
                    </div>
                    <p className="comment_item" dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
                    <div className="comment_callback">
                        <img src="/img/callback.svg"/>
                        <p>{language.otvetit}</p>
                    </div>
                    {children}
                </div>
            </li>
        })

        return parents.length > 0 ? <ul>{parents}</ul> : ''
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // debugger
                // this.props.form.resetFields()
                // this.props.checkoutLoad()
                // this.props.postCheckout(values)
            }
        })
    }


    return <div className="comments box">


        <div className="top">
            <h4>{language.kommentarii}:</h4>
            <p className="login_with_google">{language.vojti_cherez} Google
                <span><img src="/img/enter.svg"/> {language.avtorizujtes}</span>
            </p>
        </div>
        <div className="body">
            <Form onFinish={handleSubmit}>
                <div className="textarea">
                    <img src="/img/avatar.svg"/>
                    <Form.Item
                        name="comment"
                        rules={[{required: true, message: language.vvedite_vashe_soobshhenie}]}
                    >
                        <TextArea
                            rows={4}
                            placeholder={language.prisoedinitsya_k_obsuzhdeniju}
                        />
                    </Form.Item>
                </div>
                <Form.Item>
                    <Button className="red" type="primary" htmlType="submit">
                        {language.otpravit}
                    </Button>
                </Form.Item>
                {/*<FormItem*/}
                {/*    getFieldDecorator={props.form}*/}
                {/*    name={'submit'}*/}
                {/*    class="button red"*/}
                {/*    placeholder={language.otpravit}*/}
                {/*    type="submit"*/}
                {/*/>*/}
            </Form>
            {num_comments !== 0 ? <p className="number_of_comments"><img src="/img/chat.svg"/>{language_comment}</p> : ''}

        </div>
        <div className="list">
            {renderComments()}
        </div>

    </div>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
        page: state.pageReducer,
    }
}

export default connect(mapStateToProps,
    {getLanguageStrings})(Comments);

