import * as axios from "axios";


let defaultHeaders = {
    params: {},
    // withCredentials: true,
    // auth: {
    //     username: process.env.REACT_APP_WOOCOMMERCE_API_CLIENT,
    //     password: process.env.REACT_APP_WOOCOMMERCE_API_SECRET
    // }
};

export const mainApi = {

    getHome(homes_id, language) {
        defaultHeaders.params = {
            include: homes_id,
            lang: language,
            acf_format: 'standard',
        };
        return axios.get(`https://zakordonwork.com/wp-json/wp/v2/pages`,
            defaultHeaders
        ).then(function (response) {
            return response.data;
        });
    },

    getLanguages() {
        defaultHeaders.params = {
            // include: homes_id,
        };
        return axios.get(`https://zakordonwork.com/wp-json/pll/v1/languages`,
            defaultHeaders
        ).then(function (response) {
            return response.data;
        });
    },

    getCities(city_id) {
        defaultHeaders.params = {
            parent: city_id,
        };
        return axios.get(`https://zakordonwork.com/wp-json/wp/v2/vacancies`,
            defaultHeaders
        ).then(function (response) {
            return response.data;
        });
    },

    getMenu(name) {
        defaultHeaders.params = {
            name: name,
        };
        return axios.get(`https://zakordonwork.com/wp-json/api/menu`,
            defaultHeaders
        ).then(function (response) {
            return response.data;
        });
    },

    getVacanciesCategory(language) {
        defaultHeaders.params = {
            // lang: language,
            lang: 'ru',
            per_page: 100,
            acf_format: 'standard',
        };
        return axios.get(`https://zakordonwork.com/wp-json/wp/v2/vacancies`,
            defaultHeaders
        ).then(function (response) {
            return response.data;
        });
    },

    getVacancy(data) {
        // debugger
        defaultHeaders.params = {
            // vacancies: [201],
            filters: data.filters,
            slug: data.slug,
            // lang: data.language,
            lang: 'ru',
            page: data.page,
            per_page: parseInt(process.env.REACT_APP_ITEMS_PER_PAGE),
            sort: 'DESC',
            acf_format: 'standard',
            _embed: true,
        };
        return axios.get(`https://zakordonwork.com/wp-json/wp/v2/vacancy`,
            defaultHeaders
        ).then(function (response) {
            return response;
        });
    },

    getComments() {
        defaultHeaders.params = {
            per_page: 100,
            acf_format: 'standard',
            _embed: true,
        };
        return axios.get(`https://zakordonwork.com/wp-json/wp/v2/comments`,
            defaultHeaders
        ).then(function (response) {
            return response.data;
        });
    },

    getVacancyById(id) {
        defaultHeaders.params = {
            acf_format: 'standard',
            _embed: true,
        };
        return axios.get(`https://zakordonwork.com/wp-json/wp/v2/vacancy/`+id,
            defaultHeaders
        ).then(function (response) {
            return response.data;
        });
    },

    getHotVacancies(language) {
        defaultHeaders.params = {
            hot: 1,
            lang: language,
            per_page: 6,
            acf_format: 'standard',
            _embed: true,
        };
        return axios.get(`https://zakordonwork.com/wp-json/wp/v2/vacancy/`,
            defaultHeaders
        ).then(function (response) {
            return response.data;
        });
    },

    getBlog(data) {
        defaultHeaders.params = {
            slug: data.slug,
            lang: 'ru',
            page: data.page,
            per_page: parseInt(process.env.REACT_APP_ITEMS_PER_PAGE),
            // sort: 'DESC',
            acf_format: 'standard',
            _embed: true,

        };
        return axios.get(`https://zakordonwork.com/wp-json/wp/v2/posts`,
            defaultHeaders
        ).then(function (response) {
            return response;
        });
    },

    getPostByUrl(data) {
        // debugger
        defaultHeaders.params = {
            url: data,
            //lang: data.language,
            //page: data.page,
            //per_page: parseInt(process.env.REACT_APP_ITEMS_PER_PAGE),
            // sort: 'DESC',
            acf_format: 'standard',
            _embed: true,

        };
        return axios.get(`https://zakordonwork.com/wp-json/api/get-post-by-url`,
            defaultHeaders
        ).then(function (response) {
            return response;
        });
    },

    getPage(data) {
        // debugger
        defaultHeaders.params = {
            url: data.slug,
            parent: data.parent,
            // lang: data.language,
            //page: data.page,
            //per_page: parseInt(process.env.REACT_APP_ITEMS_PER_PAGE),
            // sort: 'DESC',
            acf_format: 'standard',
            _embed: true,

        };
        return axios.get(`https://zakordonwork.com/wp-json/wp/v2/pages`,
            defaultHeaders
        ).then(function (response) {
            return response;
        });
    },

    submitFormCallback(values) {
        defaultHeaders.params = values;
        return axios.get(`https://zakordonwork.com/wp-json/api/form-calback`,
            defaultHeaders
        ).then(function (response) {
            return response;
        });
    },

}



