import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Pagination} from "antd";


const PaginationContainer = (props) => {

    const renderPaginationItem = (current, type, originalElement) => {
        if (type === 'prev') {
            if (current === 0) {
                return ''
            } else if (current === 1) {
                return <Link to={'?'}><img src="/img/arrow-left.svg"/></Link>
            } else {
                return <Link to={'?page=' + current}><img src="/img/arrow-left.svg"/></Link>
            }
        } else if (type === 'next') {
            if(props.page === current){
                return ''
            }else{
                return <Link to={'?page=' + current}><img src="/img/arrow.svg"/></Link>
            }
        } else if (type === 'page') {
            if(props.page === current){
                return <p>{current}</p>
            } else if (current === 1) {
                return <Link to={'?'}>{current}</Link>
            } else {
                return <Link to={'?page=' + current}>{current}</Link>
            }
        } else if (type === 'jump-prev') {
            return <Link to={'?page=' + current}>
                <p>...</p>
                <img src="/img/arrow-double-left.svg"/>
            </Link>
        } else if (type === 'jump-next') {
            return <Link to={'?page=' + current}>
                <p>...</p>
                <img src="/img/arrow-double.svg"/>
            </Link>
        } else {
            return originalElement
        }
    }
    let per_page = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE)

    return props.total > per_page ? <Pagination
        onChange={props.onChange}
        defaultCurrent={props.defaultCurrent}
        total={props.total}
        itemRender={renderPaginationItem}
        defaultPageSize={per_page}
        showSizeChanger={false}
    /> : ''
}

let mapStateToProps = (state) => {
    return {
        // page: state.vacanciesReducer.page,
    }
}

export default connect(mapStateToProps,
    {})(PaginationContainer);

