import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";


const Logo = (props) => {

    let language = props.hom.home.acf ? props.hom.home.acf.language : ''

    return <Link to={props.hom.home.link ? props.hom.home.link.replace(process.env.REACT_APP_DOMAIN, '') : '/'}
                 className="logo">
        {
            props.effect.device === 'mobile' ?
                <img src="/img/logo-2.svg" alt="logo"/>
                :
                <img src="/img/logo.svg" alt="logo"/>
        }

        <div>
            <p className="logo_text_big">ZaKordon Work</p>
            <p dangerouslySetInnerHTML={{__html: language.rabotaem_po_vsej_ukraine_s_2008g}}/>
        </div>
    </Link>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
        effect: state.effectReducer,
    }
}

export default connect(mapStateToProps,
    {})(Logo);

