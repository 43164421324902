import React, {Component} from 'react'
import {connect} from "react-redux";
import queryString from 'query-string';
import Breadcrumbs from "../Layout/Breadcrumbs/Breadcrumbs";
import {getLanguageStrings} from "../../redux/home-reducer";
import CountryButtons from "./CountryButtons/CountryButtons";
import Filters from "./Filters/Filters";
import Vacancies from "./Vacancies/Vacancies";
import {getVacancy, changeVacanciesPage} from "../../redux/vacancies-reducer";
import {withRouter} from "react-router-dom";
import AdditionalServices from "../AdditionalServices/AdditionalServicesBanners";
import Helmet from "react-helmet";
import {setPopupClose} from "../../redux/effect-reducer";

class VacanciesContainer extends Component {

    constructor(props, state) {
        super(props, state)
        let params = queryString.parse(this.props.location.search)
        this.props.changeVacanciesPage(parseInt(params.page))
        this.props.getVacancy()
    }

    componentDidMount() {
        this.props.setPopupClose()
        document.querySelector("body").scrollTo(0,0)
    }

    componentDidUpdate() {
        this.props.setPopupClose()
        // document.querySelector("body").scrollTo(0,0)
    }

    componentWillUnmount() {

    }

    render() {

        let language = this.props.getLanguageStrings()

        let breadcrumbs = [
            {
                name: language.vakansii,
                link: this.props.hom.vacancies_url
            }
        ]

        return <div className="container">
            <Helmet
                title={language.vakansii + ' - ZaKordon Work'}
                defaultTitle={language.vakansii}
                // meta={[
                //     {"name": "description", "content": post.yoast_head_json ? post.yoast_head_json.og_description : ''},
                // ]}
                // link={post.hreflang}
            />
            <Breadcrumbs
                breadcrumbs={breadcrumbs}
            />
            <h1>{language.vakansii}</h1>
            <CountryButtons/>
            <div className="vacancies">
                <div>
                    <Filters/>
                    <AdditionalServices/>
                </div>
                <Vacancies/>
            </div>
        </div>
    }
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
    }
}

export default withRouter(
    connect(mapStateToProps,
    {getLanguageStrings, getVacancy, changeVacanciesPage, setPopupClose})(VacanciesContainer)
)



