import React, {Component} from 'react'
import {connect} from "react-redux";
import {getPage, getPostByUrl} from "../../redux/page-reducer";
import Logo from "../Layout/Header/Logo";
import City from "../Layout/Header/City";
import Language from "../Layout/Header/Language";
import CallbackButtons from "../Layout/Header/CallbackButtons";
import Phones from "../Elements/Phones/Phones";
import Menu from "../Layout/Header/Menu";
import PhoneMenuButtons from "../Layout/Header/PhoneMenuButtons";
import AdditionalServices from "../AdditionalServices/AdditionalServices";
import BreadcrumbsPage from "../Layout/Breadcrumbs/BreadcrumbsPage";
import VisaApplication from "../AdditionalServices/VisaApplication/VisaApplication";
import DefaultPage from "./DefaultPage/DefaultPage";
import {setPopupClose} from "../../redux/effect-reducer";


class PagesContainer extends Component {

    constructor(props, state) {
        super(props, state);
        this.props.getPage({slug: window.location.pathname})
        // this.props.getPage('viza-v-polshu')
    }

    componentDidMount() {
        this.props.setPopupClose()
        document.querySelector("body").scrollTo(0,0)
    }

    componentDidUpdate() {
        this.props.setPopupClose()
        document.querySelector("body").scrollTo(0,0)
        if(window.location.pathname !== this.props.slug){
            this.props.getPage({slug: window.location.pathname})
        }
    }

    render() {
        // page

        const renderHeader = () => {
            switch (this.props.template) {
                case 'Страница по умолчанию':
                    return <DefaultPage/>
                case 'Дополнительные услуги':
                    return <AdditionalServices/>
                case 'Оформление визы':
                    return <VisaApplication/>
                case 'Виза':
                    return 'Виза'
                case 'Страховка для выезда за границу':
                    return 'Страховка для выезда за границу'
                case 'Оформление по А1':
                    return 'Оформление по А1'
                case 'Контакты':
                    return 'Контакты'
                default:
                    return ''
            }
        }

        return <div>
            <div className="container">
                <BreadcrumbsPage
                    breadcrumbs={this.props.breadcrumbs}
                    last_link={this.props.link}
                />
            </div>
            {renderHeader()}
        </div>

    }
}

let mapStateToProps = (state) => {
    // debugger
    return {
        template: state.pageReducer.template,
        page: state.pageReducer.page,
        link: state.pageReducer.link,
        slug: state.pageReducer.slug,
        breadcrumbs: state.pageReducer.breadcrumbs,
    }
}

export default connect(mapStateToProps,
    {getPostByUrl, getPage, setPopupClose})(PagesContainer);



