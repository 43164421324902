import {mainApi} from "../api/main-api";
import {setHotVacancies} from "./vacancies-reducer";
import {getBlog} from "./blog-reducer";

const SET_HOME = 'SET_HOME';
const SET_LANGUAGES = 'SET_LANGUAGES';
const SET_LANGUAGE = 'SET_LANGUAGE';
const SET_CITIES = 'SET_CITIES';
const SET_CITY = 'SET_CITY';
const SET_MENU = 'SET_MENU';
const SET_VACANCIES_CATEGORY = 'SET_VACANCIES_CATEGORY';
const SET_COMMENTS = 'SET_COMMENTS';
const SET_BLOG = 'SET_BLOG';
const SET_LOAD_BUTTON = 'SET_LOAD_BUTTON';
const SET_DONE_FORM = 'SET_DONE_FORM';


let initialState = {
    language_root: 'ru',
    languages_init: ['ru', 'ua', 'de', 'pl'],
    languages: [],
    language: '',
    language_strings: [],
    homes_id: [43, 45, 1184, 1266],
    home: [],
    cities_keys: {ru: 82, ua: 84, de: 596, pl: 598},
    countries_keys: {ru: 98, ua: 100},
    cities: [],
    city: '',
    menu: [],
    vacancies_url: '/vacancies',
    blog_url: '/blog',
    vacancies_category: [],
    comments: [],
    blog: [],
    load_button: false,
    done_form: false,
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HOME: {
            return {
                ...state,
                home: action.data.shift(),
                // language_strings: action.data.shift().acf ? action.data.shift().acf.language : '',
            }
        }
        case SET_LANGUAGES: {
            return {
                ...state,
                languages: action.data,
            }
        }
        case SET_LANGUAGE: {
            let language = window.location.pathname.split('/')[1]
            language = state.languages_init.find((e) => e === language )
            language = language ? language : state.language_root
            return {
                ...state,
                language: language,
            }
        }
        case SET_CITIES: {
            // debugger
            let defaultValue = action.data.find((e) => {
                return e.translations['ru'] === 96
            })
            return {
                ...state,
                cities: action.data,
                city: defaultValue ? defaultValue.id : '',
            }
        }
        case SET_CITY: {
            return {
                ...state,
                city: action.data,
            }
        }
        case SET_MENU: {
            return {
                ...state,
                menu: action.data,
            }
        }
        case SET_VACANCIES_CATEGORY: {
            return {
                ...state,
                vacancies_category: action.data,
            }
        }
        case SET_COMMENTS: {
            return {
                ...state,
                comments: action.data,
            }
        }
        case SET_BLOG: {
            return {
                ...state,
                blog: action.data,
            }
        }
        case SET_LOAD_BUTTON: {
            return {
                ...state,
                load_button: action.data,
            }
        }
        case SET_DONE_FORM: {
            return {
                ...state,
                done_form: action.data,
            }
        }
        default:
            return state;
    }

}

export const setHome = (data) => ({type: SET_HOME, data})
export const setLanguages = (data) => ({type: SET_LANGUAGES, data})
export const setLanguage = (data) => ({type: SET_LANGUAGE, data})
export const setCities = (data) => ({type: SET_CITIES, data})
export const setCity = (data) => ({type: SET_CITY, data})
export const setMenu = (data) => ({type: SET_MENU, data})
export const setVacanciesCategory = (data) => ({type: SET_VACANCIES_CATEGORY, data})
export const setComments = (data) => ({type: SET_COMMENTS, data})
export const setBlog = (data) => ({type: SET_BLOG, data})
export const setLoadButton = (data) => ({type: SET_LOAD_BUTTON, data})
export const setDoneForm = (data) => ({type: SET_DONE_FORM, data})

export const start = () => {
    return (dispatch, getState) => {
        dispatch(setLanguage())

        const homes_id = getState().homeReducer.homes_id
        const language = getState().homeReducer.language
        mainApi.getHome(homes_id, language).then(data => {
            dispatch(setHome(data))
        });

        mainApi.getLanguages().then(data => {
            dispatch(setLanguages(data))
        });

        const city_id = getState().homeReducer.cities_keys[getState().homeReducer.language]
        mainApi.getCities(city_id).then(data => {
            dispatch(setCities(data))
        });

        mainApi.getMenu('top_'+language).then(data => {
            dispatch(setMenu(data))
        });

        mainApi.getVacanciesCategory(language).then(data => {
            dispatch(setVacanciesCategory(data))
        });

        mainApi.getComments().then(data => {
            dispatch(setComments(data))
        });

        mainApi.getHotVacancies(language).then(data => {
            dispatch(setHotVacancies(data))
        });

        const page = getState().BlogReducer.page
        mainApi.getBlog({language, page}).then(data => {
            dispatch(setBlog(data))
        });


    }
}

export const selectCity = (data) => {
    return (dispatch, getState) => {
        dispatch(setCity(data))
    }
}

export const getTo = (data) => {
    return (dispatch, getState) => {
        return data ? data.replace(process.env.REACT_APP_DOMAIN, '') : '/'
    }
}

export const getLanguageStrings = (data) => {
    return (dispatch, getState) => {
        let hom = getState().homeReducer
        return hom.home.acf ? hom.home.acf.language : ''
    }
}

export const getFormStrings = (data) => {
    return (dispatch, getState) => {
        let hom = getState().homeReducer
        return hom.home.acf ? hom.home.acf.form : ''
    }
}

export const submitFormCallback = (values) => {
    return (dispatch, getState) => {
        dispatch(setLoadButton(true))
        mainApi.submitFormCallback(values).then(data => {
            dispatch(setLoadButton(false))
            dispatch(setDoneForm(true))
            setTimeout(() => {
                dispatch(setDoneForm(false))
            }, 5000);
        });
    }
}



export default homeReducer;