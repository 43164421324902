import React, {Component} from 'react'
import {connect} from "react-redux";
import {phonesDropdown, setMenuMobile} from "../../../redux/effect-reducer";


class PhoneMenuButtons extends Component {
    render() {


        return <div className="phone_menu_buttons">
            <img onClick={this.props.phonesDropdown} className="ico_phone" src="/img/phone-2.svg" alt="phone"/>
            {this.props.effect.device === 'mobile' ?
                this.props.effect.menu_mobile ?
                    <img onClick={this.props.setMenuMobile} src="/img/close-2.svg" alt="menu"/>
                    :
                    <img onClick={this.props.setMenuMobile} src="/img/menu-2.svg" alt="menu"/>
                :
                this.props.effect.menu_mobile ?
                    <img onClick={this.props.setMenuMobile} src="/img/close.svg" alt="menu"/>
                    :
                    <img onClick={this.props.setMenuMobile} src="/img/menu.svg" alt="menu"/>
            }
        </div>

    }
}

let mapStateToProps = (state) => {
    return {
        effect: state.effectReducer,
    }
}

export default connect(mapStateToProps,
    {phonesDropdown, setMenuMobile})(PhoneMenuButtons);

