import React, {Component} from 'react'

const Img = (props) => {

    let srcSet = props.src + '.webp'

    return <picture className={props.class}>
        <source type="image/webp" srcSet={srcSet}/>
        <img src={props.src} alt={props.alt} />
    </picture>
}

export default Img
