import React, {Component} from 'react'
import {connect} from "react-redux";
import PopupCallback from "./PopupCallback";
import PopupQuestion from "./PopupQuestion";
import {popupClose} from "../../redux/effect-reducer";


class PopupContainer extends Component {

    constructor(props, state) {
        super(props, state);
    }


    render() {

        return (
            this.props.effect.popup_callback ||
            this.props.effect.popup_question
        ) ?
            <div>
                {this.props.effect.popup_callback ? <PopupCallback/> : ''}
                {this.props.effect.popup_question ? <PopupQuestion/> : ''}
                <div onClick={this.props.popupClose} className='mask'/>
            </div>
            :
            ''
    }
}

let mapStateToProps = (state) => {
    return {
        effect: state.effectReducer,
    }
}

export default connect(mapStateToProps,
    {popupClose})(PopupContainer);



