import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import BlogItems from "./BlogItems";

const BlogPreview = (props) => {

    return <div className=" dusk box blog">
        <div className="container">
            <h3 className="title_head">{props.language.nashi_novosti}</h3>
            <BlogItems
                blogItems={props.blog}
            />
            <Link to="/" className="button blue button_big">
                {props.language.smotret_vse_novosti}
                <img src="/img/arrow-3.svg" />
            </Link>
        </div>
    </div>

}

let mapStateToProps = (state) => {
    return {
        language: state.homeReducer.home.acf ? state.homeReducer.home.acf.language : '',
        blog: state.BlogReducer.blog,
    }
}

export default connect(mapStateToProps,
    {})(BlogPreview)
