import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";

const CallingYouReceive = (props) => {

    const renderItems = (items = []) => {
        if (items){
            let response = items.map((item, index) => {
                return <li>
                    {item.text}
                </li>
            });
            return <ul>{response}</ul>
        }else {
            return ''
        }
    }
    return <div className="calling_you_receive content container">
        <h5>{props.calling_you_receive}</h5>
        {renderItems(props.calling_you_receive_list)}
    </div>

}

let mapStateToProps = (state) => {
    return {
        calling_you_receive: state.homeReducer.home.acf ? state.homeReducer.home.acf.form.calling_you_receive : '',
        calling_you_receive_list: state.homeReducer.home.acf ? state.homeReducer.home.acf.form.calling_you_receive_list : [],
    }
}

export default connect(mapStateToProps,
    {})(CallingYouReceive)
