import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Youtube from "../../Elements/Youtube/Youtube";

const ComprehensiveAssistance = (props) => {

    const renderItems = (items = []) => {
        let response = items.map((item, index) => {
            return <li>
                {item.text}
            </li>
        });
        return <ul className="list_style_type_double_check">{response}</ul>
    }
    return <div className="comprehensive_assistance dusk box">
        <div className="container">
            <div>
                <h5>{props.comprehensive_assistance.title}</h5>
                <Youtube
                    video={props.comprehensive_assistance.video}
                />
            </div>
            {renderItems(props.comprehensive_assistance.list)}
        </div>
    </div>

}

let mapStateToProps = (state) => {
    return {
        comprehensive_assistance: state.pageReducer.page.acf ? state.pageReducer.page.acf.visa_application.comprehensive_assistance : '',
    }
}

export default connect(mapStateToProps,
    {})(ComprehensiveAssistance)
