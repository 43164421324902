import React from 'react'
import {connect} from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from "../../Elements/Img/Img";
import SlickArrowLeft from "../../Elements/SlickArrow/SlickArrowLeft";
import SlickArrowRight from "../../Elements/SlickArrow/SlickArrowRight";

const Reviews = (props) => {


    const renderVideos = (items = []) => {


        const settings = {
            prevArrow: <SlickArrowLeft />,
            nextArrow: <SlickArrowRight />,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {breakpoint: 1199, settings: {slidesToShow: 3}},
                {breakpoint: 991, settings: {slidesToShow: 2}},
                {breakpoint: 575, settings: {slidesToShow: 1}},
            ]
        };

        if(items){
            let response = items.map((item, index) => {
                return <div key={index} className="item">
                    <video  controls="controls" poster={item.poster ? item.poster.sizes.thumbnail : ''}>
                        {/*<source src={item.video.url} />*/}
                        <source  />
                    </video>
                </div>
            });
            return <Slider {...settings}>{response}</Slider>
        }else {
            return ''
        }

    }

    const renderReviews = (items = []) => {
        const settings = {
            prevArrow: <SlickArrowLeft />,
            nextArrow: <SlickArrowRight />,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {breakpoint: 1199, settings: {slidesToShow: 3}},
                {breakpoint: 991, settings: {slidesToShow: 2}},
                {breakpoint: 575, settings: {slidesToShow: 1}},
            ]
        };

        if (items){
            let response = items.map((item, index) => {
                return <li key={index}>
                    <Img
                        src={item.img ? item.img.sizes.blog : ''}
                        alt={item.img ? item.img.alt : ''}
                        class="thumbnail"
                    />
                </li>
            });
            return <Slider {...settings}>{response}</Slider>
        }else {
            return ''
        }

    }


    return <div className="box reviews">
        <div className="container">
            <h2 className="title_head">{props.reviews.title}</h2>
            <p className="description">{props.reviews.description}</p>
            {renderVideos(props.reviews.items_1)}
            <span className="separate"/>
            <p className="description">{props.reviews.description_2}</p>
            {renderReviews(props.reviews.items_2)}
        </div>
    </div>

}

let mapStateToProps = (state) => {
    return {
        reviews: state.homeReducer.home.acf ? state.homeReducer.home.acf.reviews : '',
    }
}

export default connect(mapStateToProps,
    {})(Reviews)
