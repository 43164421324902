import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import AdditionalServicesBanners from "./AdditionalServicesBanners";
import Breadcrumbs from "../Layout/Breadcrumbs/Breadcrumbs";
import FreeConsultation from "../Layout/Forms/FreeConsultation";
import Visas from "./VisaApplication/Visas";
import Helmet from "react-helmet";


const AdditionalServices = (props) => {

    let post = props.page.page
    return <div className="additional_services_banners container ">
        <Helmet
            title={post.yoast_head_json ? post.yoast_head_json.og_title : ''}
            defaultTitle={post.name}
            meta={[
                {"name": "description", "content": post.yoast_head_json ? post.yoast_head_json.og_description : ''},
            ]}
            link={post.hreflang}
        />
        <AdditionalServicesBanners/>
        <FreeConsultation/>
    </div>
}

let mapStateToProps = (state) => {
    return {
        //hom: state.homeReducer,
        page: state.pageReducer,
    }
}

export default connect(mapStateToProps,
    {})(AdditionalServices);

