import React, {Component} from 'react'
import {connect} from "react-redux";
import Slider from "./Slider/Slider";
import JobInEurope from "./JobInEurope/JobInEurope";
import HotVacancies from "./HotVacancies/HotVacancies";
import {getHotVacancies} from "../../redux/vacancies-reducer";
import FreeConsultation from "../Layout/Forms/FreeConsultation";
import FiveSteps from "./FiveSteps/FiveSteps";
import PartnerLicenseCertificates from "./PartnerLicenseCertificates/PartnerLicenseCertificates";
import Reviews from "./Reviews/Reviews";
import BlogCategory from "../Blog/BlogPreview";
import Helmet from "react-helmet";
import {setPopupClose} from "../../redux/effect-reducer";

class HomeContainer extends Component {

    constructor(props, state) {
        super(props, state)
        // props.getHotVacancies()
    }

    componentDidMount() {
        this.props.setPopupClose()
        document.querySelector("body").scrollTo(0,0)
    }

    componentDidUpdate() {
        this.props.setPopupClose()
        // document.querySelector("body").scrollTo(0,0)
    }

    componentWillUnmount() {

    }


    render() {
        let post = this.props.home.home
        return <div>
            <Helmet
                title={post.yoast_head_json ? post.yoast_head_json.og_title : ''}
                defaultTitle={post.name}
                meta={[
                    {"name": "description", "content": post.yoast_head_json ? post.yoast_head_json.og_description : ''},
                ]}
                link={post.hreflang}
            />
            <Slider/>
            <JobInEurope/>
            <HotVacancies/>
            <FreeConsultation/>
            <FiveSteps/>
            <PartnerLicenseCertificates/>
            <Reviews/>
            <BlogCategory/>
        </div>
    }
}

let mapStateToProps = (state) => {
    return {
        // cart: state.cartReducer,
        home: state.homeReducer,
    }
}

export default connect(mapStateToProps,
    {getHotVacancies, setPopupClose})(HomeContainer);



