import React, {Component} from 'react'
import {connect} from "react-redux";
import {Carousel} from "antd";
import Img from "../../Elements/Img/Img";

const Slider = (props) => {

    const renderComponent = () => {

        let sliders = []
        if(props.effect.device !== 'mobile'){
            sliders = [
                {src: 'https://zakordonwork.com/wp-content/uploads/2022/02/banner-1.jpg', alt: 'banner-1', link: ''},
                {src: 'https://zakordonwork.com/wp-content/uploads/2022/02/banner-2.jpg', alt: 'banner-2', link: ''},
                {src: 'https://zakordonwork.com/wp-content/uploads/2022/02/banner-3.jpg', alt: 'banner-3', link: ''},
                {src: 'https://zakordonwork.com/wp-content/uploads/2022/02/banner-4.jpg', alt: 'banner-4', link: ''},
            ]
        }else{
            sliders = [
                {src: 'https://zakordonwork.com/wp-content/uploads/2022/02/banner-1-767x600.jpg', alt: 'banner-1', link: ''},
                {src: 'https://zakordonwork.com/wp-content/uploads/2022/02/banner-2-767x600.jpg', alt: 'banner-2', link: ''},
                {src: 'https://zakordonwork.com/wp-content/uploads/2022/02/banner-3-767x600.jpg', alt: 'banner-3', link: ''},
                {src: 'https://zakordonwork.com/wp-content/uploads/2022/02/banner-4-767x600.jpg', alt: 'banner-4', link: ''},
            ]
        }

        // banner-1-767x600.jpg

        let response = sliders.map((item, index) => {
            return <div className="slid" key={index}>
                    <div className="slider_text">
                        <div className="container">
                        <p className="slider_big_text" dangerouslySetInnerHTML={{__html: props.hom.home.acf && props.hom.home.acf.slider[index] ? props.hom.home.acf.slider[index].title : ''}}/>
                        <p className="slider_small_text" dangerouslySetInnerHTML={{__html: props.hom.home.acf && props.hom.home.acf.slider[index] ? props.hom.home.acf.slider[index].description : ''}}/>
                    </div>
                </div>
                <Img
                    src={item.src}
                    alt={item.alt}
                />
            </div>
        });

        return <div className="slider"><Carousel
            effect="fade"
            autoplay={false}
            // showArrows={false}
            // autoPlay={false}
            // infiniteLoop={true}
            // showStatus={false}
            // showThumbs={false}
            // animationHandler={'fade'}
            swipeable={true}
        >{response}</Carousel></div>
    }

    return renderComponent()
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
        effect: state.effectReducer,
    }
}

export default connect(mapStateToProps,
    {})(Slider);

