import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Img from "../Elements/Img/Img";
import {getTo} from "../../redux/home-reducer";


const AdditionalServicesBanners = (props) => {

    let language = props.hom.home.acf ? props.hom.home.acf.language : ''
    let additional_services = props.hom.home.acf ? props.hom.home.acf.additional_services : ''

    const renderItems = (items) => {
        let response = []
        if (items.length > 0){
            response = items.map((item, index) => {
                return <li key={index}><Link to={props.getTo(item.link)}>
                    <Img
                        src={item.img.url}
                        alt={item.img.alt}
                    />
                </Link></li>
            })
        }
        return <ul>{response}</ul>
    }

    return <aside className="additional_services">
        <p>{language.dopolnitelnye_uslugi}</p>
        {renderItems(additional_services)}
    </aside>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
    }
}

export default connect(mapStateToProps,
    {getTo})(AdditionalServicesBanners);

