import {mainApi} from "../api/main-api";
import {setHome} from "./home-reducer";
import {setBlog} from "./blog-reducer";
import {format} from "date-fns";

const SET_PAGE = 'SET_PAGE';
const SET_SLUG = 'SET_SLUG';
// const SET_COMMENTS = 'SET_COMMENTS';


let initialState = {
    page: [],
    slug: '',
    breadcrumbs: [],
    title: '',
    loading: false,
};

const pageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGE: {
            let page = action.data.data[0]

            let breadcrumbs = ''
            if(action.data.data[0]){
                breadcrumbs = action.data.data[0].yoast_head_json.schema['@graph'].find((e) => {
                    return e['@type'] === "BreadcrumbList";
                } ).itemListElement
            }


            return {
                ...state,
                page: action.data.data[0],
                template: action.data.data[0] ? action.data.data[0].acf.template : '',
                link: action.data.data[0] ? action.data.data[0].link : '',
                breadcrumbs: breadcrumbs,
                title: page && page.title ? page.title.rendered : '',

            }
        }
        case SET_SLUG: {
            return {
                ...state,
                slug: action.data.slug,
            }
        }

        default:
            return state;
    }

}

export const setPage = (data) => ({type: SET_PAGE, data})
export const setSlug = (data) => ({type: SET_SLUG, data})

export const getVacancy = (data) => {
    return (dispatch, getState) => {
        dispatch(setSlug(data))
        mainApi.getVacancy(data).then(data => {
            dispatch(setPage(data))
            // получить комменты страници языка ru
            // if (data[0].lang === 'ru'){
            //     let comments = data[0] && data[0]._embedded ? data[0]._embedded.replies[0] : ''
            //     dispatch(setComments(comments))
            // }else{
            //     mainApi.getVacancyById(data[0].translations.ru).then(data => {
            //         debugger
            //     });
            // }
        });
    }
}

export const getPost = (data) => {
    return (dispatch, getState) => {
        dispatch(setSlug(data))
        mainApi.getBlog(data).then(data => {
            dispatch(setPage(data))
        });
    }
}

export const getPostByUrl = (data) => {
    return (dispatch, getState) => {
        dispatch(setSlug(data))
        mainApi.getPostByUrl(data).then(data => {
            dispatch(setPage(data))
        });
    }
}

export const getPage = (data) => {
    return (dispatch, getState) => {
        dispatch(setSlug(data))
        mainApi.getPage(data).then(data => {
            dispatch(setPage(data))
        });
    }
}

export const getTitle = (vacancy) => {
    return (dispatch, getState) => {
        return vacancy && vacancy.title ? vacancy.title.rendered : ''
    }
}

export const getPrice = (vacancy) => {
    return (dispatch, getState) => {
        return vacancy && vacancy.acf ? vacancy.acf.price : ''
    }
}

export const getMeasurement = (vacancy) => {
    return (dispatch, getState) => {
        let language = getState().homeReducer.home.acf ? getState().homeReducer.home.acf.language : ''
        return vacancy && vacancy.acf && language ? vacancy.acf.currency + '/' + language.hour : ''
    }
}
//
export const getCountry = (vacancy) => {
    return (dispatch, getState) => {

        let vacancies_category = getState().homeReducer.vacancies_category
        let countries_keys = getState().homeReducer.countries_keys
        let language = getState().homeReducer.language

        if (vacancies_category.length > 0 && vacancy.id && language) {
            return vacancies_category.find((e) => {
                return e.parent === countries_keys[language] &&
                    vacancy.vacancies && vacancy.vacancies.includes(e.id)
            })
        }
    }
}

export const getPlaceOfWork = (country, vacancy) => {
    return (dispatch, getState) => {
        let language = getState().homeReducer.home.acf ? getState().homeReducer.home.acf.language : ''
        let vacancy_city = ''
        if (vacancy.acf && vacancy.acf.vacancy_city) {
            vacancy_city = ', ' + language.g + ' ' + vacancy.acf.vacancy_city
        }
        return country && vacancy ? country.name + vacancy_city : '';
    }
}

export const getThumbnail = (vacancy, sizes) => {
    return (dispatch, getState) => {
        let src, alt, response

        if (vacancy && vacancy._embedded && vacancy._embedded['wp:featuredmedia']) {
            src = vacancy._embedded['wp:featuredmedia'][0].media_details.sizes[sizes] ?
                vacancy._embedded['wp:featuredmedia'][0].media_details.sizes[sizes].source_url : vacancy._embedded['wp:featuredmedia'][0].source_url
            alt = vacancy._embedded && vacancy._embedded['wp:featuredmedia'] && vacancy._embedded['wp:featuredmedia'][0].media_details.sizes[sizes] ?
                vacancy._embedded['wp:featuredmedia'][0].alt_text : ''
        }

        response = {src: src, alt: alt}

        return response
    }
}

export const getContent = (data) => {
    return (dispatch, getState) => {
        return data && data.content ? data.content.rendered : ''
    }
}

export const getExcerpt = (data) => {
    return (dispatch, getState) => {
        return data && data.excerpt ? data.excerpt.rendered : ''
    }
}

export const getFlag = (country) => {
    return (dispatch, getState) => {
        return country && country.acf ? country.acf.flag.url : ''
    }
}

export const getDate = (data) => {
    return (dispatch, getState) => {
        if(data && data.date){
            let date = new Date(data.date);
            return format(date, "dd-MM-yyyy");
        }
    }
}

export const getLink = (data) => {
    return (dispatch, getState) => {
        return data && data.link ? data.link : '';
    }
}

export const getAcf = (data) => {
    return (dispatch, getState) => {
        return data && data.acf ? data.acf : '';
    }
}

// export const getBreadcrumbs = (data) => {
//     return (dispatch, getState) => {
//         if(data.yoast_head_json.schema.@graph){
//             return 1
//         }
//
//     }
// }

export default pageReducer;