import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Img from "../../Elements/Img/Img";
import {getFormStrings, getLanguageStrings, submitFormCallback} from "../../../redux/home-reducer";
import {Button, Form, Input} from "antd";
const { TextArea } = Input;

const FreeConsultation = (props) => {

    const renderComponent = () => {

        const [form] = Form.useForm();
        const onFinish = (values) => {
            props.submitFormCallback(values)
            form.setFieldsValue({name: '', tel: '', comment: ''});
        };

        return <div className="container box form">
            <h3 className="title_head">{props.form.poluchit_besplatnuju_konsultaciju_nashego_specialista}</h3>
            <div className="top">
                <div className="left">
                    <Img
                        src="https://zakordonwork.com/wp-content/uploads/2022/04/spec-1.jpg"
                        alt="spec-1"
                    />
                    <div className="description">
                        <p>{props.form.vas_prokonsultiruet_specialist}</p>
                        <p className="bold">{props.form.vas_prokonsultiruet_specialist_name}</p>
                    </div>
                </div>
                {/*<form>*/}
                {/*    <input type="text" placeholder={form.vashe_imya}/>*/}
                {/*    <input type="phone" placeholder={form.vash_nomer_telefona}/>*/}
                {/*    <textarea placeholder={form.kommentarij}/>*/}
                {/*    <input type="submit" value={form.poluchit_konsultaciju}/>*/}
                {/*</form>*/}
                <Form
                    onFinish={onFinish}
                    form={form}
                >
                    <Form.Item
                        name="name"
                        rules={[{required: true, message: props.form.vvedite_vashe_imya}]}
                    >
                        <Input
                            placeholder={props.form.vashe_imya}
                        />
                    </Form.Item>
                    <Form.Item
                        name="tel"
                        rules={[{required: true, message: props.form.vvedite_vash_nomer_telefona}]}
                    >
                        <Input
                            placeholder={props.form.vash_nomer_telefona}
                        />
                    </Form.Item>
                    <Form.Item
                        name="comment"
                    >
                        <TextArea
                            rows={4}
                            placeholder={props.form.kommentarij}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button className="button_big" type="primary" htmlType="submit">
                            {props.form.poluchit_konsultaciju}
                        </Button>
                    </Form.Item>
                    {props.done_form ?
                        <p className="done">Ваше сообщение отправлено</p> : ''
                    }
                </Form>
            </div>
        </div>
    }

    return renderComponent()

}

let mapStateToProps = (state) => {
    return {
        homeReducer: state.homeReducer,
        form: state.homeReducer.home.acf ? state.homeReducer.home.acf.form : '',
        load_button: state.homeReducer.load_button,
        done_form: state.homeReducer.done_form,
    }
}

export default connect(mapStateToProps,
    {getFormStrings, getLanguageStrings, submitFormCallback})(FreeConsultation);

