import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Visas from "../../AdditionalServices/VisaApplication/Visas";
import FreeConsultation from "../../Layout/Forms/FreeConsultation";
import CallingYouReceive from "../../Layout/Forms/CallingYouReceive";
import ComprehensiveAssistance from "../../AdditionalServices/VisaApplication/ComprehensiveAssistance";
import Img from "../../Elements/Img/Img";
import MessengersButtons from "../../Elements/Messengers/MessengersButtons";
import {getContent, getThumbnail, getTitle} from "../../../redux/page-reducer";
import Helmet from "react-helmet";


const DefaultPage = (props) => {

    // let language = props.getLanguageStrings()
    let post = props.page.page
    let title = props.getTitle(post)
    let thumbnail = props.getThumbnail(post, 'employers')
    let content = props.getContent(post)
    // let date = props.getDate(post)

    return <div className="container default_page">
        <Helmet
            title={post.yoast_head_json ? post.yoast_head_json.og_title : ''}
            defaultTitle={post.name}
            meta={[
                {"name": "description", "content": post.yoast_head_json ? post.yoast_head_json.og_description : ''},
            ]}
            link={post.hreflang}
        />
        <Img
            src={thumbnail.src}
            alt={thumbnail.alt}
            class="employers"
        />
        <h1 dangerouslySetInnerHTML={{__html: title}}/>
        <div className="content" dangerouslySetInnerHTML={{__html: content}}/>
        <FreeConsultation/>
        <MessengersButtons/>
    </div>
}

let mapStateToProps = (state) => {
    return {
        page: state.pageReducer,
    }
}

export default connect(mapStateToProps,
    {getTitle, getThumbnail, getContent})(DefaultPage)