import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";

const FiveSteps = (props) => {

    const renderItems = (items = []) => {
        if (items){
            let response = items.map((item, index) => {
                return <li key={index}>
                    <span>{'0' + (index + 1)}</span>
                    {item.text}
                </li>
            });
            return <ul>{response}</ul>
        }else {
            return ''
        }

    }
    return <div className="container box five_steps">
        <h3 className="title_head">{props.five_steps.title}</h3>
        {renderItems(props.five_steps.items)}
    </div>

}

let mapStateToProps = (state) => {
    return {
        five_steps: state.homeReducer.home.acf ? state.homeReducer.home.acf.five_steps : '',
    }
}

export default connect(mapStateToProps,
    {})(FiveSteps)
