import React, {Component} from 'react'
import {connect} from "react-redux";
import {getCountry, getPrice, getVacancy} from "../../redux/page-reducer";
import Vacancy from "./Vacancy/Vacancy";
import AdditionalServices from "../AdditionalServices/AdditionalServicesBanners";
import Helmet from "react-helmet";
import {setPopupClose} from "../../redux/effect-reducer";

class VacancyContainer extends Component {


    constructor(props, state) {
        super(props, state);
        this.props.getVacancy({slug: this.props.match.params.slug})
    }

    componentDidMount() {
        this.props.setPopupClose()
        document.querySelector("body").scrollTo(0,0)
    }

    componentDidUpdate() {
        document.querySelector("body").scrollTo(0,0)
        if(this.props.match.params.slug !== this.props.page.slug){
            this.props.setPopupClose()
            this.props.getVacancy({slug: this.props.match.params.slug})
        }
    }

    componentWillUnmount() {

    }

    render() {
        let post = this.props.page.page

        return <div className="container flex">
            <Helmet
                title={post.yoast_head_json ? post.yoast_head_json.og_title : ''}
                defaultTitle={post.name}
                meta={[
                    {"name": "description", "content": post.yoast_head_json ? post.yoast_head_json.og_description : ''},
                ]}
                link={post.hreflang}
            />
            {this.props.effect.device === 'desktop' ? <AdditionalServices/> : ''}
            <Vacancy/>
        </div>
    }
}

let mapStateToProps = (state) => {
    return {
        effect: state.effectReducer,
        // hom: state.homeReducer,
        page: state.pageReducer,
    }
}

export default connect(mapStateToProps,
    {getVacancy, getPrice, getCountry, setPopupClose})(VacancyContainer);



