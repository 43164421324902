import React, {Component} from 'react'
import {Link} from "react-router-dom";


const Youtube = (props) => {

    return <iframe width="560" height="315"
                   src={'https://www.youtube.com/embed/' + props.video + '?controls=0'}
                   title="YouTube video player"
                   frameBorder="0"
                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                   allowFullScreen></iframe>
}

export default Youtube
