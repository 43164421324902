import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Messengers from "../../Elements/Messengers/Messengers";


const FooterBottom = (props) => {

    return <div className="bottom">
        <div className="container">
            {/*<div className="menu_bottom">*/}
            {/*    <div>*/}
            {/*        <p className="title">Працевлаштування</p>*/}
            {/*        <ul>*/}
            {/*            <li><a>Робота в Польщі</a></li>*/}
            {/*            <li><a>Робота в Німеччині</a></li>*/}
            {/*            <li><a>Робота в Чехії</a></li>*/}
            {/*            <li><a>Робота в Литві</a></li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*    <div className="visa">*/}
            {/*        <p className="title">Візи</p>*/}
            {/*        <ul>*/}
            {/*            <li><a>Віза в Польщу</a></li>*/}
            {/*            <li><a>Віза в США</a></li>*/}
            {/*            <li><a>Віза в Чехію</a></li>*/}
            {/*            <li><a>Віза в Канаду</a></li>*/}
            {/*            <li><a>Віза в Німеччину</a></li>*/}
            {/*            <li><a>Віза в Великобританію</a></li>*/}
            {/*            <li><a>Віза в Літву</a></li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <p className="title">Додаткові послуги</p>*/}
            {/*        <ul>*/}
            {/*            <li><a>Анкета</a></li>*/}
            {/*            <li><a>Страховка</a></li>*/}
            {/*            <li><a>Відрядження А1</a></li>*/}
            {/*            <li><a>Черга в візовий центр без присутності</a></li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <p className="title">Інформація</p>*/}
            {/*        <ul>*/}
            {/*            <li><a>Блог/новини</a></li>*/}
            {/*            <li><a>О нас</a></li>*/}
            {/*            <li><a>Контакти</a></li>*/}
            {/*        </ul>*/}
            {/*        <Messengers/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="copyright">
                <div>
                    <p>{props.language.davajte_druzhit_v_socialnyh_setyah}</p>
                    <Messengers/>
                    <ul>
                        <li><a href="/"><img src="/img/social-facebook.svg"/></a></li>
                        <li><a href="/"><img src="/img/social-instagramm.svg"/></a></li>
                        <li><a href="/"><img src="/img/social-youtube.svg"/></a></li>
                    </ul>
                </div>
                <p>© Zakordon Work 2021</p>
            </div>
        </div>
    </div>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
        language: state.homeReducer.home.acf ? state.homeReducer.home.acf.language : '',
    }
}

export default connect(mapStateToProps,
    {})(FooterBottom);

