import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import homeReducer from "./home-reducer";
import effectReducer from "./effect-reducer";
import pageReducer from "./page-reducer";
import vacanciesReducer from "./vacancies-reducer";
import BlogReducer from "./blog-reducer";
import VisaApplicationReducer from "./visaapplication-reducer";

let reducers = combineReducers({
    homeReducer,
    effectReducer,
    pageReducer,
    vacanciesReducer,
    BlogReducer,
    VisaApplicationReducer,
});

export default createStore(reducers, applyMiddleware(thunk));

