import React, {Component} from 'react'
import {Link} from "react-router-dom";


const Messengers = (props) => {

    return <ul className="messengers">
        <li><a href="/"><img src="/img/messanger-viber.svg"/></a></li>
        <li><a href="/"><img src="/img/messanger-telegramm.svg"/></a></li>
        <li><a href="/"><img src="/img/messanger-watsapp.svg"/></a></li>
        <li><a href="/"><img src="/img/messanger-facebook-m.svg"/></a></li>
    </ul>
}

export default Messengers
