import {mainApi} from "../api/main-api";
import {setHome} from "./home-reducer";

const SET_VACANCIES_CATEGORY = 'SET_VACANCIES_CATEGORY';
const SET_VACANCIES = 'SET_VACANCIES';
const SET_VACANCIES_PAGE = 'SET_VACANCIES_PAGE';
const SET_FILTER = 'SET_FILTER';
const SET_HOT_VACANCIES = 'SET_HOT_VACANCIES';

let initialState = {
    // vacancies_category: [],
    vacancies: [],
    filters: [],
    page: 1,
    total_vacancies: 0,
    vacancies_category: [],
    loading: false,
    hot_vacancies: [],
};

const vacanciesReducer = (state = initialState, action) => {
    switch (action.type) {
        // case SET_VACANCIES_CATEGORY: {
        //     return {
        //         ...state,
        //         vacancies_category: action.data,
        //     }
        // }
        case SET_VACANCIES: {
            return {
                ...state,
                vacancies: action.data.data,
                total_vacancies: parseInt(action.data.headers['x-wp-total']),
            }
        }
        case SET_VACANCIES_PAGE: {
            return {
                ...state,
                page: action.data ? action.data : 1,
            }
        }
        case SET_FILTER: {
            let newFilters = state.filters
            if(!newFilters.includes(parseInt(action.data))){
                newFilters.push(parseInt(action.data))
            }else {
                newFilters = newFilters.filter(item => item !== parseInt(action.data))
            }
            return {
                ...state,
                filters: newFilters
            }
        }
        case SET_HOT_VACANCIES: {
            return {
                ...state,
                hot_vacancies: action.data,
            }
        }
        default:
            return state;
    }

}

// export const setVacanciesCategory = (data) => ({type: SET_VACANCIES_CATEGORY, data})
export const setVacancies = (data) => ({type: SET_VACANCIES, data})
export const setVacanciesPage = (data) => ({type: SET_VACANCIES_PAGE, data})
export const setFilter = (data) => ({type: SET_FILTER, data})
export const setHotVacancies = (data) => ({type: SET_HOT_VACANCIES, data})

// export const getVacanciesCategory = (data) => {
//     return (dispatch, getState) => {
//         const language = getState().homeReducer.language
//         mainApi.getVacanciesCategory(language).then(data => {
//             dispatch(setVacanciesCategory(data))
//         });
//     }
// }

export const getVacancy = (slug) => {
    return (dispatch, getState) => {
        const language = getState().homeReducer.language
        const page = getState().vacanciesReducer.page
        const filters = getState().vacanciesReducer.filters
        mainApi.getVacancy({filters, language, page}).then(data => {
            dispatch(setVacancies(data))
        });
    }
}

export const changeVacanciesPage = (data) => {
    return (dispatch, getState) => {
        dispatch(setVacanciesPage(data))
        document.querySelector("body").scrollTo(0,0)
    }
}

export const toggleFilter = (data) => {
    return (dispatch, getState) => {
        dispatch(setFilter(data))
    }
}

export const getHotVacancies = (data) => {
    return (dispatch, getState) => {
        const hot_vacancies = getState().vacanciesReducer.hot_vacancies
        const language = getState().homeReducer.language
        if (hot_vacancies.length === 0){
            mainApi.getHotVacancies(language).then(data => {
                dispatch(setHotVacancies(data))
            });
        }

    }
}


export default vacanciesReducer;