import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import { format } from "date-fns";
import Breadcrumbs from "../Layout/Breadcrumbs/Breadcrumbs";
import Img from "../Elements/Img/Img";
import {getBlog} from "../../redux/blog-reducer";
import {getContent, getDate, getLink, getPost, getThumbnail, getTitle} from "../../redux/page-reducer";
import {getLanguageStrings} from "../../redux/home-reducer";
import MessengersButtons from "../Elements/Messengers/MessengersButtons";
import BlogPreview from "./BlogPreview";
import Comments from "../Layout/Comments/Comments";
import Helmet from "react-helmet";


class Post extends Component {

    constructor(props, state) {
        super(props, state)
        this.props.getPost({slug: this.props.match.params.slug})
    }

    componentDidMount() {
        document.querySelector("body").scrollTo(0,0)
    }

    componentDidUpdate() {
        document.querySelector("body").scrollTo(0,0)
        if(this.props.match.params.slug !== this.props.page.slug){
            this.props.getPost({slug: this.props.match.params.slug})
        }
    }



    render() {
        let language = this.props.getLanguageStrings()
        let post = this.props.page.page
        let title = this.props.getTitle(post)
        let thumbnail = this.props.getThumbnail(post, 'blog_main')
        let content = this.props.getContent(post)
        let date = this.props.getDate(post)


        // console.log(formattedDate);

        let breadcrumbs = [
            {
                name: language.blognovosti,
                link: this.props.hom.blog_url
            },
            {
                name: title,
                link: this.props.getLink(post)
            }
        ]

        return <div className="container">
            <Helmet
                title={post.yoast_head_json ? post.yoast_head_json.og_title : ''}
                defaultTitle={post.name}
                meta={[
                    {"name": "description", "content": post.yoast_head_json ? post.yoast_head_json.og_description : ''},
                ]}
                link={post.hreflang}
            />
            <Breadcrumbs
                breadcrumbs={breadcrumbs}
            />
            <div className="post">
                <Img
                    src={thumbnail.src}
                    alt={thumbnail.alt}
                />
                <div>
                    <p className="calendar"><img src="/img/calendar.svg"/>{date}</p>
                    <h1 dangerouslySetInnerHTML={{__html: title}}/>
                    <div className="content" dangerouslySetInnerHTML={{__html: content}}/>
                </div>
            </div>
            <MessengersButtons/>
            <BlogPreview/>
            <Comments/>
        </div>
    }
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
        page: state.pageReducer,
    }
}

export default connect(mapStateToProps,
    {getPost, getLanguageStrings, getTitle, getThumbnail, getContent, getDate, getLink})(Post);

