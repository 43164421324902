import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getCountry, getFlag, getThumbnail, getTitle} from "../../../redux/page-reducer";
import {getTo} from "../../../redux/home-reducer";
import Img from "../../Elements/Img/Img";
import PriceBlock from "../../Vacancy/PriceBlock/PriceBlock";
import Icon from "../../Elements/icon/Icon";
import PaginationContainer from "../../Elements/Pagination/PaginationContainer";
import {changeVacanciesPage, getVacancy} from "../../../redux/vacancies-reducer";


const Vacancies = (props) => {

    const renderItems = () => {
        let response = props.vacancies.map((item, index) => {
            let title = props.getTitle(item)
            let thumbnail = props.getThumbnail(item, 'vacancy')
            let country = props.getCountry(item)
            let flag = props.getFlag(country)

            // debugger
            // let place_of_work = props.getPlaceOfWork(country, vacancy)

            return <li key={index}>
                <Link key={index} to={props.getTo(item.link)}>
                    {item.acf.hot ? <img src="/img/hot.svg" className="hot" alt="hot"/> : ''}
                    <Img
                        src={thumbnail.src}
                        alt={thumbnail.alt}
                        class="thumbnail"
                    />
                    <div className="panel">
                        <p className="title" dangerouslySetInnerHTML={{__html: title}}/>
                        <PriceBlock
                            vacancy={item}
                        />
                    </div>
                    <Icon
                        src={flag}
                        alt="flag"
                        class="flag"
                    />
                </Link>
            </li>
        });

        return <ul className="items">{response}</ul>
    }

    const changePagination = (page) => {
        props.changeVacanciesPage(page)
        props.getVacancy()
    }

    return <div style={{width: "100%"}}>
        {renderItems()}
        <PaginationContainer
            page={props.page}
            defaultCurrent={props.page}
            total={props.total_vacancies}
            onChange={changePagination}
        />
    </div>

}

let mapStateToProps = (state) => {
    return {
        vacancies: state.vacanciesReducer.vacancies,
        page: state.vacanciesReducer.page,
        total_vacancies: state.vacanciesReducer.total_vacancies,
    }
}

export default connect(mapStateToProps,
    {getTitle, getThumbnail, getTo, getCountry, getFlag, changeVacanciesPage, getVacancy})(Vacancies)


