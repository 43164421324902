import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Select} from "antd";
import {selectCity} from "../../../redux/home-reducer";

const {Option} = Select;

const City = (props) => {

    let language = props.hom.home.acf ? props.hom.home.acf.language : ''

    let cities = props.hom.cities.map((item, index) => {
        return <Option key={index} value={item.id}>{item.name}</Option>
    })

    return <div className="city">
        <p>
            <img src="/img/loca.svg" alt="loca"/>
            {language.vyberite_region}:
        </p>
        <Select
            // defaultOpen="true"
            dropdownClassName="city_dropdown"
            onChange={(value) => {
                props.selectCity(value)
            }}
            value={props.hom.city}>
            {cities}
        </Select>

    </div>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
    }
}

export default connect(mapStateToProps,
    {selectCity})(City);

