import React, {Component} from 'react'

const Icon = (props) => {
    if(props.src){
        return <img className={props.class} src={props.src} alt={props.alt} />
    }else{
        return ''
    }
}

export default Icon
