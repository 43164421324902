import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {getTo} from "../../../redux/home-reducer";
import {getTitle} from "../../../redux/page-reducer";

const Breadcrumbs = (props) => {

    const renderBreadcrumbs = () => {

        let breadcrumbs = [
            {
                name: props.getTitle(props.hom.home),
                link: props.getTo(props.hom.home.link)
            }
        ]

        breadcrumbs = breadcrumbs.concat(props.breadcrumbs)

        let response = breadcrumbs.map((breadcrumb, key) => {
            return renderBreadcrumb(breadcrumbs, breadcrumb, key)
        })
        return <div className="breadcrumb" itemProp="breadcrumb" itemScope=""
                    itemType="http://schema.org/BreadcrumbList">
            {response}
        </div>
    };

    const renderBreadcrumb = (breadcrumbs, breadcrumb, key) => {

        let response, link, meta, previousItem, nextItem = ''

        if (key + 1 !== breadcrumbs.length) {
            link = <Link itemProp="item" to={breadcrumb.link}>
                <span itemProp="name">{breadcrumb.name}</span>
            </Link>
        } else {
            link = <div>
                <link itemProp="item" href={breadcrumb.link}/>
                <p itemProp="name">{breadcrumb.name}</p>
            </div>
        }


        meta =
            <meta itemProp="position" content={key + 1}/>

        if (key > 0) {
            previousItem = <div itemProp="previousItem" itemScope="" itemType="http://schema.org/ListItem">
                <meta itemProp="name" content={breadcrumbs[key - 1].name}/>
                <div itemProp="item" itemScope="" itemType="http://schema.org/Thing">
                    <link itemProp="url" href={breadcrumbs[key - 1].link}/>
                </div>
                <meta itemProp="position" content={key}/>
            </div>
        }

        if (key + 1 !== breadcrumbs.length && breadcrumbs[key + 1]) {
            nextItem = <div itemProp="nextItem" itemScope="" itemType="http://schema.org/ListItem">
                <meta itemProp="name" content={breadcrumbs[key + 1].name}/>
                <div itemProp="item" itemScope="" itemType="http://schema.org/Thing">
                    <link itemProp="url" href={breadcrumbs[key + 1].link}/>
                </div>
                <meta itemProp="position" content={key + 2}/>
            </div>
        }

        response =
            <div key={key} itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
                {link}
                {meta}
                {previousItem}
                {nextItem}
            </div>

        return response
    }


    return renderBreadcrumbs()

}

// export default Breadcrumbs;

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
        // lang: state.languageReducer,
        // cat: state.categoryReducer,
        // prod: state.productReducer,
    }
}

export default connect(mapStateToProps,
    {getTo, getTitle})(Breadcrumbs);
