import React, {Component} from 'react'
import {Link} from "react-router-dom";


const Mail = (props) => {

    return <div className="mail">
        <img src="/img/mail.svg"/>
        <a href="mailto:info@zakordonwork.com">info@zakordonwork.com</a>
    </div>
}

export default Mail
