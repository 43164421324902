import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Img from "../../Elements/Img/Img";
import Breadcrumbs from "../../Layout/Breadcrumbs/Breadcrumbs";
import {
    getContent,
    getCountry,
    getMeasurement,
    getPlaceOfWork,
    getPrice,
    getThumbnail,
    getTitle
} from "../../../redux/page-reducer";
import {getLanguageStrings} from "../../../redux/home-reducer";
import Comments from "../../Layout/Comments/Comments";
import PriceBlock from "../PriceBlock/PriceBlock";
import AdditionalServices from "../../AdditionalServices/AdditionalServicesBanners";
import {popupCallback} from "../../../redux/effect-reducer";


const Vacancy = (props) => {

    let language = props.getLanguageStrings()
    let vacancy = props.page.page
    let title = props.getTitle(vacancy)
    let thumbnail = props.getThumbnail(vacancy, 'medium')
    let content = props.getContent(vacancy)


    let breadcrumbs = [
        {
            name: language.vakansii,
            link: props.hom.vacancies_url
        },
        {
            name: title,
            link: props.link
        }
    ]

    return <div className="vacancy">
        <Breadcrumbs
            breadcrumbs={breadcrumbs}
        />
        {/*<h1>{title}</h1>*/}
        <div className="panel">
            <Img
                src={thumbnail.src}
                alt={thumbnail.alt}
            />
            <div>
                <h1>{title}</h1>
                <div className="bottom">
                    <PriceBlock
                        vacancy={vacancy}
                    />
                    <p onClick={props.popupCallback} className='button red'>{language.svyazatsya_s_rabotodatelem}</p>
                </div>
            </div>
        </div>
        <div className="content" dangerouslySetInnerHTML={{__html: content}}/>

        {props.effect.device === 'desktop' ? '' : <AdditionalServices/>}
        <Comments/>
    </div>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
        page: state.pageReducer,
        link: state.pageReducer.link,
        effect: state.effectReducer,
    }
}

export default connect(mapStateToProps,
    {popupCallback, getLanguageStrings, getPrice, getMeasurement, getCountry, getPlaceOfWork, getTitle, getThumbnail, getContent})(Vacancy);

