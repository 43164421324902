import React, {Component} from 'react'
// import {Link} from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import {connect} from "react-redux";
import {getTo} from "../../../redux/home-reducer";


const Menu = (props) => {
    const renderMenu = (parent_id = 0) => {
        if (props.menu){
            let parents = props.menu.filter((e) => {
                let menu_item_parent = parseInt(e.menu_item_parent)
                return menu_item_parent === parent_id
            }).map((item, index) => {
                let children = renderMenu(item.ID)
                return <li className={children ? 'has_child' : ''} key={index}>
                    <Link to={props.getTo(item.url)} >{item.title}</Link>
                    {children}
                </li>
            })
            return parents.length > 0 ? <ul>{parents}</ul> : ''
        }
        return []
    };

    return <div className="menu">
        {renderMenu()}
    </div>
}

let mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps,
    {getTo})(Menu);



