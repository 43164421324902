import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import effectReducer from "../../redux/effect-reducer";


const PopupQuestion = (props) => {

    return <div className={props.effect.popup_question ? 'active' : ''}>PopupQuestion</div>
}

let mapStateToProps = (state) => {
    return {
        effect: state.effectReducer,
    }
}

export default connect(mapStateToProps,
    {})(PopupQuestion);

