import React, {Component} from 'react'
import {connect} from "react-redux"
import {Link} from "react-router-dom"


const MessengersButtons = (props) => {

    return <div className="messengers container box">
        <h5 class="title_head">{props.language.napishite_nam_v_messendzher}</h5>
        <ul className="messengers_buttons">
            <li><a href="/"><img src="/img/messanger-viber.svg"/>{props.language.napisat_v_viber}</a></li>
            <li><a href="/"><img src="/img/messanger-telegramm.svg"/>{props.language.napisat_v_telegram}</a></li>
            <li><a href="/"><img src="/img/messanger-watsapp.svg"/>{props.language.napisat_v_whatsapp}</a></li>
            <li><a href="/"><img src="/img/messanger-facebook-m.svg"/>{props.language.napisat_v_messenger}</a></li>
        </ul>
    </div>
}

let mapStateToProps = (state) => {
    return {
        language: state.homeReducer.home.acf ? state.homeReducer.home.acf.language : '',
    }
}

export default connect(mapStateToProps,
    {})(MessengersButtons);
