import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {popupCallback, popupQuestion} from "../../../redux/effect-reducer";


const CallbackButtons = (props) => {

    let language = props.hom.home.acf ? props.hom.home.acf.language : ''

    return <div>
        <p onClick={props.popupCallback} className='button blue'>{language.zakazat_obratnyj_zvonok}</p>
        <p onClick={props.popupCallback} className='button red'>{language.zapolnit_anketu}</p>
    </div>
}

let mapStateToProps = (state) => {
    return {
        hom: state.homeReducer,
    }
}

export default connect(mapStateToProps,
    {popupCallback, popupQuestion})(CallbackButtons);

